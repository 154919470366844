import { Tab } from "@headlessui/react";
import Label from "components/Label/Label";
import { countryList } from "dataStructures/countryList";
import mastercardPng from "images/mastercard.svg";
import visaPng from "images/vis.png";
import React, { FC, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import { actionCreators, State } from "state";
import emailjs from 'emailjs-com';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle'
import fareRulesText from "./fareRulesText";
import indiFareRules from "./indiFareRules";
import fareRulesText2 from "./fareRulesText2";
import indiFareRules2 from "./indiFareRules2";

export interface CheckOutPageProps {
  className?: string;
}

const CheckOutPage: FC<CheckOutPageProps> = ({ className = "" }) => {

  const history = useHistory();
  const dispatch = useDispatch();

  const { storeCustomerData } = bindActionCreators(actionCreators, dispatch);

  const state = useSelector((state: State) => state.storeFlightDataOneReducer)

  const secondState = useSelector((state: State) => state.storeFlightDataTwoReducer)

  const basicState = useSelector((state: State) => state.getBasicDetailsReducer)

  const sendEmail = () => {
    var templateParams = {
      first_name: Object.values(firstName[0])[0],
      last_name: Object.values(lastName[0])[0],
      confirmation_number: confirmationNumber,
      number_of_passengers: state.flightDataOneObject?.number_of_passengers,
      flight_date: state.flightDataOneObject?.departing_at_date,
      phone: phone,
      email: email,
      origin: from,
      destination: to
    };

    emailjs.send('service_vm38wff', 'template_94k37ue', templateParams, '1drt90QGDzO3W0qz0')
      .then(function (result) {
        console.log(result.text)
      }, function (error) {
        console.log(error.text)
      });
  }

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [scroll, setScroll] = useState<DialogProps['scroll']>('paper');

  const handleClickOpen = (scrollType: DialogProps['scroll']) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen2 = (scrollType: DialogProps['scroll']) => () => {
    setOpen2(true);
    setScroll(scrollType);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const handleClickOpen3 = (scrollType: DialogProps['scroll']) => () => {
    setOpen3(true);
    setScroll(scrollType);
  };

  const handleClose3 = () => {
    setOpen3(false);
  };

  const handleClickOpen4 = (scrollType: DialogProps['scroll']) => () => {
    setOpen4(true);
    setScroll(scrollType);
  };

  const handleClose4 = () => {
    setOpen4(false);
  };

  const descriptionElementRef = useRef<HTMLElement>(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const descriptionElementRef2 = useRef<HTMLElement>(null);
  useEffect(() => {
    if (open2) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open2]);

  const [firstName, setFirstName] = useState([{}])
  const [lastName, setLastName] = useState([{}])
  const [cardNumber, setCardNumber] = useState('')
  const [cardholderName, setCardholderName] = useState('')
  const [expiryDate, setExpiryDate] = useState('')
  const [cvv, setCvv] = useState('')
  const [streetAddress, setStreetAddress] = useState('')
  const [country, setCountry] = useState('')
  const [zipCode, setZipCode] = useState('')
  const [city, setCity] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')

  const [allValid, setAllValid] = useState(false)
  const [validCard, setValidCard] = useState<boolean>()
  const [validCvv, setValidCvv] = useState<boolean>()
  const [validEmail, setValidEmail] = useState<boolean>()
  const [validPhone, setValidPhone] = useState<boolean>()

  const [validPassengerFirst, setValidPassengerFirst] = useState<boolean>()
  const [validPassengerLast, setValidPassengerLast] = useState<boolean>()



  const [validCardholderName, setValidCardholderName] = useState<boolean>()
  const [validExpiryDate, setValidExpiryDate] = useState<boolean>()
  const [validStreetAddress, setValidStreetAddress] = useState<boolean>()
  const [validCountry, setValidCountry] = useState<boolean>()
  const [validZipCode, setValidZipCode] = useState<boolean>()
  const [validCity, setValidCity] = useState<boolean>()
  const [validFirstName, setValidFirstName] = useState<boolean>()
  const [validLastName, setValidLastName] = useState<boolean>()

  const [clicked, setClicked] = useState(false)


  const [completedFields, setCompletedFields] = useState<boolean>()

  const confirmationNumber = Math.floor(Math.random() * 1000000000);


  var cardRegex = "^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14})$"
  var cvvRegex = "^[0-9]{3,4}$"
  var emailRegex = ".+@.+\.[A-Za-z]+$"
  var phoneRegex = "^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$"


  var from = state.flightDataOneObject?.origin_airport_code
  var to = state.flightDataOneObject?.destination_airport_code
  var airlineLogo = state.flightDataOneObject?.airline_logo


  var customerData = {
    firstName: firstName,
    lastName: lastName,
    cardNumber: cardNumber,
    cardholderName: cardholderName,
    expiryDate: expiryDate,
    cvv: cvv,
    streetAddress: streetAddress,
    country: country,
    zipCode: zipCode,
    city: city,
    email: email,
    phone: phone,
    confirmationNumber: confirmationNumber,
    from: from,
    to: to,
    airlineLogo: airlineLogo
  };

  const handleFirstName = (e: any, i: any) => {
    const { value, name } = e.target;
    const newState = [...firstName];
    newState[i] = {
      ...newState[i],
      [name]: value
    };
    setFirstName(newState);
  };

  const handleLastName = (e: any, i: any) => {
    const { value, name } = e.target;
    const newState = [...lastName];
    newState[i] = {
      ...newState[i],
      [name]: value
    };
    setLastName(newState);
  };


  const handleSubmit = (e: any) => {
    try {
      if (cardNumber.replace(/\s/g, '').match(cardRegex)) {
        setValidCard(true)
      } else {
        setValidCard(false)
      }

      if (cvv.match(cvvRegex)) {
        setValidCvv(true)
      } else {
        setValidCvv(false)
      }

      if (email.match(emailRegex)) {
        setValidEmail(true)
      } else {
        setValidEmail(false)
      }

      if (phone.match(phoneRegex)) {
        setValidPhone(true)
      } else {
        setValidPhone(false)
      }

      if (cardholderName === '') {
        setValidCardholderName(false)
      } else {
        setValidCardholderName(true)
      }

      if (expiryDate === '') {
        setValidExpiryDate(false)
      } else {
        setValidExpiryDate(true)
      }

      if (streetAddress === '') {
        setValidStreetAddress(false)
      } else {
        setValidStreetAddress(true)
      }

      if (country === '') {
        setValidCountry(false)
      } else {
        setValidCountry(true)
      }

      if (zipCode === '') {
        setValidZipCode(false)
      } else {
        setValidZipCode(true)
      }

      if (city === '') {
        setValidCity(false)
      } else {
        setValidCity(true)
      }

      if (firstName === [{}]) {
        setValidFirstName(false)
      } else {
        setValidFirstName(true)
      }

      if (lastName === [{}]) {
        setValidLastName(false)
      } else {
        setValidLastName(true)
      }

      if (validCard === true &&
        validCvv === true &&
        validEmail === true &&
        validPhone === true &&
        validFirstName === true &&
        validLastName === true &&
        validCity === true &&
        validZipCode === true &&
        validCountry === true &&
        validStreetAddress === true &&
        validExpiryDate === true) {
        e.currentTarget.loading = true;
        storeCustomerData(customerData)
        setClicked(true)
        setTimeout(function () {
          sendEmail()
          // history.push("/pay-done")
          alert("Could not complete payment with the provided details, please try another payment card or try again later.")
          setClicked(false)
        }, 5000);
      }
    } catch (e) {
      setTimeout(function () {
        setClicked(true)
        alert("Could not complete payment with the provided details, please try another payment card or try again later.")
        setClicked(false)
      }, 5000);
    }


  }

  const renderSidebar = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
        <div className="flex flex-col sm:flex-row sm:items-center">
          <div className="flex-shrink-0 w-full sm:w-40">
            <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
              {/* <img src={state.flightDataOneObject?.airline_logo} /> */}
              {basicState.basicDetailsObject?.returnType === "oneWay" ? <div className="flex-shrink-0 w-full sm:w-40"><div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
                <img src={state.flightDataOneObject?.airline_logo} />
              </div></div> : <div />}
              {/* </div> */}

              {basicState.basicDetailsObject?.returnType === "roundTrip" ?
                <div style={{ display: 'flex' }}>
                  <span style={{ flex: '1' }}>
                    <img src={state.flightDataOneObject?.airline_logo} />
                  </span>
                  <span style={{ flex: '1' }}>
                    <img src={secondState.flightDataTwoObject?.airline_logo} />
                  </span>
                </div> : <div />}

            </div>
          </div>
          <div className="py-5 sm:px-5 space-y-3">
            <div>
              {/* <span className="text-sm text-neutral-500 dark:text-neutral-400">
                Flight from {state.flightDataOneObject?.origin_airport_name} to {state.flightDataOneObject?.destination_airport_name}
              </span> */}
              {basicState.basicDetailsObject?.returnType === "oneWay" && state.flightDataOneObject?.layover === false ?
                <span className="text-base font-medium mt-1 block">
                  Flight from {state.flightDataOneObject?.origin_city} ({state.flightDataOneObject?.origin_airport_name}) to {state.flightDataOneObject?.destination_city} ({state.flightDataOneObject?.destination_airport_name})
                </span>
                : basicState.basicDetailsObject?.returnType === "oneWay" && state.flightDataOneObject?.layover === true ?
                  <span className="text-base font-medium mt-1 block">
                    Flight from {state.flightDataOneObject?.origin_city} ({state.flightDataOneObject?.origin_airport_name}) to {state.flightDataOneObject?.layover_city} ({state.flightDataOneObject?.layover_origin}) to {state.flightDataOneObject?.destination_city} ({state.flightDataOneObject?.destination_airport_name})
                  </span>
                  : <div />}

              {/* LAYOVER TYPE SHH */}
              {basicState.basicDetailsObject?.returnType === "roundTrip" && state.flightDataOneObject?.layover === false && secondState.flightDataTwoObject?.layover === false ?
                <span className="text-base font-medium mt-1 block">
                  Outbound: {state.flightDataOneObject?.origin_city} ({state.flightDataOneObject?.origin_airport_name}) to {state.flightDataOneObject?.destination_city} ({state.flightDataOneObject?.destination_airport_name})
                  <div className="w-10 border-b border-neutral-200  dark:border-neutral-700 mt-2 mb-2"></div>
                  Inbound: {secondState.flightDataTwoObject?.origin_city} ({secondState.flightDataTwoObject?.origin_airport_name}) to {secondState.flightDataTwoObject?.destination_city} ({secondState.flightDataTwoObject?.destination_airport_name})
                </span> :
                basicState.basicDetailsObject?.returnType === "roundTrip" && state.flightDataOneObject?.layover === true && secondState.flightDataTwoObject?.layover === false ?
                  <span className="text-base font-medium mt-1 block">
                    Outbound: {state.flightDataOneObject?.origin_city} ({state.flightDataOneObject?.origin_airport_name}) to {state.flightDataOneObject?.layover_city} ({state.flightDataOneObject?.layover_origin}) to {state.flightDataOneObject?.destination_city} ({state.flightDataOneObject?.destination_airport_name})
                    <div className="w-10 border-b border-neutral-200  dark:border-neutral-700 mt-2 mb-2"></div>
                    Inbound: {secondState.flightDataTwoObject?.origin_city} ({secondState.flightDataTwoObject?.origin_airport_name}) to {secondState.flightDataTwoObject?.destination_city} ({secondState.flightDataTwoObject?.destination_airport_name})
                  </span> :
                  basicState.basicDetailsObject?.returnType === "roundTrip" && state.flightDataOneObject?.layover === false && secondState.flightDataTwoObject?.layover === true ?
                    <span className="text-base font-medium mt-1 block">
                      Outbound: {state.flightDataOneObject?.origin_city} ({state.flightDataOneObject?.origin_airport_name}) to {state.flightDataOneObject?.destination_city} ({state.flightDataOneObject?.destination_airport_name})
                      <div className="w-10 border-b border-neutral-200  dark:border-neutral-700 mt-2 mb-2"></div>
                      Inbound: {secondState.flightDataTwoObject?.origin_city} ({secondState.flightDataTwoObject?.origin_airport_name}) to {secondState.flightDataTwoObject?.layover_city} ({secondState.flightDataTwoObject?.layover_origin}) to {secondState.flightDataTwoObject?.destination_city} ({secondState.flightDataTwoObject?.destination_airport_name})
                    </span>
                    :
                    basicState.basicDetailsObject?.returnType === "roundTrip" && state.flightDataOneObject?.layover === true && secondState.flightDataTwoObject?.layover === true ?
                      <span className="text-base font-medium mt-1 block">
                        Outbound: {state.flightDataOneObject?.origin_city} ({state.flightDataOneObject?.origin_airport_name}) to {state.flightDataOneObject?.layover_city} ({state.flightDataOneObject?.layover_origin}) to {state.flightDataOneObject?.destination_city} ({state.flightDataOneObject?.destination_airport_name})
                        <div className="w-10 border-b border-neutral-200  dark:border-neutral-700 mt-2 mb-2"></div>
                        Inbound: {secondState.flightDataTwoObject?.origin_city} ({secondState.flightDataTwoObject?.origin_airport_name}) to {secondState.flightDataTwoObject?.layover_city} ({secondState.flightDataTwoObject?.layover_origin}) to {secondState.flightDataTwoObject?.destination_city} ({secondState.flightDataTwoObject?.destination_airport_name})
                      </span>
                      :
                      <div />}
            </div>




            {basicState.basicDetailsObject?.returnType === "oneWay" && state.flightDataOneObject?.layover === false ?
              <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
                {state.flightDataOneObject?.origin_airport_code} · {state.flightDataOneObject?.destination_airport_code}
              </span>
              : basicState.basicDetailsObject?.returnType === "oneWay" && state.flightDataOneObject?.layover === true ?
                <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
                  {state.flightDataOneObject?.origin_airport_code} · {state.flightDataOneObject?.layover_airport} · {state.flightDataOneObject?.destination_airport_code}
                </span>
                : <div />
            }

            {basicState.basicDetailsObject?.returnType === "roundTrip" && state.flightDataOneObject?.layover === false && secondState.flightDataTwoObject?.layover === false ?
              <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
                {state.flightDataOneObject?.origin_airport_code} · {state.flightDataOneObject?.destination_airport_code}
                <div className="w-10 border-b border-neutral-200  dark:border-neutral-700 mt-2 mb-2"></div>
                {secondState.flightDataTwoObject?.origin_airport_code} · {secondState.flightDataTwoObject?.destination_airport_code}
              </span>
              : basicState.basicDetailsObject?.returnType === "roundTrip" && state.flightDataOneObject?.layover === true && secondState.flightDataTwoObject?.layover === false ?
                <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
                  {state.flightDataOneObject?.origin_airport_code} · {state.flightDataOneObject?.layover_airport} · {state.flightDataOneObject?.destination_airport_code}
                  <div className="w-10 border-b border-neutral-200  dark:border-neutral-700 mt-2 mb-2"></div>
                  {secondState.flightDataTwoObject?.origin_airport_code} · {secondState.flightDataTwoObject?.destination_airport_code}
                </span>
                : basicState.basicDetailsObject?.returnType === "roundTrip" && state.flightDataOneObject?.layover === false && secondState.flightDataTwoObject?.layover === true ?
                  <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
                    {state.flightDataOneObject?.origin_airport_code} · {state.flightDataOneObject?.destination_airport_code}
                    <div className="w-10 border-b border-neutral-200  dark:border-neutral-700 mt-2 mb-2"></div>
                    {secondState.flightDataTwoObject?.origin_airport_code} · {secondState.flightDataTwoObject?.layover_airport} · {secondState.flightDataTwoObject?.destination_airport_code}
                  </span>
                  : basicState.basicDetailsObject?.returnType === "roundTrip" && state.flightDataOneObject?.layover === true && secondState.flightDataTwoObject?.layover === true ?
                    <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
                      {state.flightDataOneObject?.origin_airport_code} · {state.flightDataOneObject?.layover_airport} · {state.flightDataOneObject?.destination_airport_code}
                      <div className="w-10 border-b border-neutral-200  dark:border-neutral-700 mt-2 mb-2"></div>
                      {secondState.flightDataTwoObject?.origin_airport_code} · {secondState.flightDataTwoObject?.layover_airport} · {secondState.flightDataTwoObject?.destination_airport_code}
                    </span>
                    : <div />
            }
            {/* <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div> */}
            {/* <StartRating /> */}
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <h3 className="text-2xl font-semibold">Flight Details</h3>
          {basicState.basicDetailsObject?.returnType === "oneWay" && state.flightDataOneObject?.layover === false ?
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span>{state.flightDataOneObject?.departing_at_date}</span>
              <span>{state.flightDataOneObject?.origin_airport_code} to {state.flightDataOneObject?.destination_airport_code}</span>
              <span>{state.flightDataOneObject?.arriving_at_date}</span>
              <span>{state.flightDataOneObject?.operating_carrier_code} {state.flightDataOneObject?.flight_number}</span>
            </div>
            : basicState.basicDetailsObject?.returnType === "oneWay" && state.flightDataOneObject?.layover === true ?
              <div>
                <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                  <span>{state.flightDataOneObject?.departing_at_date}</span>
                  <span>{state.flightDataOneObject?.origin_airport_code} to {state.flightDataOneObject?.layover_airport}</span>
                  <span>{state.flightDataOneObject?.layover_arrival}</span>
                  <span>{state.flightDataOneObject?.operating_carrier_code} {state.flightDataOneObject?.flight_number}</span>
                </div>
                <div className="border-solid border-2 rounded-lg border-sky-500 mt-2 mb-2">
                  <p className="text-center">
                    {state.flightDataOneObject?.layoverDiff}
                  </p>
                </div>
                <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                  <span>{state.flightDataOneObject?.layover_departure}</span>
                  <span>{state.flightDataOneObject?.layover_airport} to {state.flightDataOneObject?.destination_airport_code}</span>
                  <span>{state.flightDataOneObject?.arriving_at_date}</span>
                  <span>{state.flightDataOneObject?.layover_operating_carrier_code} {state.flightDataOneObject?.layover_flight_number}</span>
                </div>
              </div> :
              <div />}




          {basicState.basicDetailsObject?.returnType === "roundTrip" && state.flightDataOneObject?.layover === false && secondState.flightDataTwoObject?.layover === false ?
            <div>
              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span>{state.flightDataOneObject?.departing_at_date}</span>
                <span>{state.flightDataOneObject?.origin_airport_code} to {state.flightDataOneObject?.destination_airport_code}</span>
                <span>{state.flightDataOneObject?.arriving_at_date}</span>
                <span>{state.flightDataOneObject?.operating_carrier_code} {state.flightDataOneObject?.flight_number}</span>
              </div>
              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span>{secondState.flightDataTwoObject?.departing_at_date}</span>
                <span>{secondState.flightDataTwoObject?.origin_airport_code} to {secondState.flightDataTwoObject?.destination_airport_code}</span>
                <span>{secondState.flightDataTwoObject?.arriving_at_date}</span>
                <span>{secondState.flightDataTwoObject?.operating_carrier_code} {secondState.flightDataTwoObject?.flight_number}</span>

              </div>
            </div>
            : basicState.basicDetailsObject?.returnType === "roundTrip" && state.flightDataOneObject?.layover === true && secondState.flightDataTwoObject?.layover === false ?
              <div>
                <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                  <span>{state.flightDataOneObject?.departing_at_date}</span>
                  <span>{state.flightDataOneObject?.origin_airport_code} to {state.flightDataOneObject?.layover_airport}</span>
                  <span>{state.flightDataOneObject?.layover_arrival}</span>
                  <span>{state.flightDataOneObject?.operating_carrier_code} {state.flightDataOneObject?.flight_number}</span>
                </div>
                <div className="border-solid border-2 rounded-lg border-sky-500 mt-2 mb-2">
                  <p className="text-center">
                    {state.flightDataOneObject?.layoverDiff} stop
                  </p>
                </div>
                <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                  <span>{state.flightDataOneObject?.layover_departure}</span>
                  <span>{state.flightDataOneObject?.layover_airport} to {state.flightDataOneObject?.destination_airport_code}</span>
                  <span>{state.flightDataOneObject?.arriving_at_date}</span>
                  <span>{state.flightDataOneObject?.layover_operating_carrier_code} {state.flightDataOneObject?.layover_flight_number}</span>
                </div>
                <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                  <span>{secondState.flightDataTwoObject?.departing_at_date}</span>
                  <span>{secondState.flightDataTwoObject?.origin_airport_code} to {secondState.flightDataTwoObject?.destination_airport_code}</span>
                  <span>{secondState.flightDataTwoObject?.arriving_at_date}</span>
                  <span>{secondState.flightDataTwoObject?.operating_carrier_code} {secondState.flightDataTwoObject?.flight_number}</span>
                </div>
              </div>
              : basicState.basicDetailsObject?.returnType === "roundTrip" && state.flightDataOneObject?.layover === false && secondState.flightDataTwoObject?.layover === true ?
                <div>
                  <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                    <span>{state.flightDataOneObject?.departing_at_date}</span>
                    <span>{state.flightDataOneObject?.origin_airport_code} to {state.flightDataOneObject?.destination_airport_code}</span>
                    <span>{state.flightDataOneObject?.arriving_at_date}</span>
                    <span>{state.flightDataOneObject?.operating_carrier_code} {state.flightDataOneObject?.flight_number}</span>
                  </div>
                  <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                    <span>{secondState.flightDataTwoObject?.departing_at_date}</span>
                    <span>{secondState.flightDataTwoObject?.origin_airport_code} to {secondState.flightDataTwoObject?.layover_airport}</span>
                    <span>{secondState.flightDataTwoObject?.layover_arrival}</span>
                    <span>{secondState.flightDataTwoObject?.operating_carrier_code} {secondState.flightDataTwoObject?.flight_number}</span>
                  </div>
                  <div className="border-solid border-2 rounded-lg border-sky-500 mt-2 mb-2">
                    <p className="text-center">
                      {secondState.flightDataTwoObject?.layoverDiff} stop
                    </p>
                  </div>
                  <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                    <span>{secondState.flightDataTwoObject?.layover_departure}</span>
                    <span>{secondState.flightDataTwoObject?.layover_airport} to {secondState.flightDataTwoObject?.destination_airport_code}</span>
                    <span>{secondState.flightDataTwoObject?.arriving_at_date}</span>
                    <span>{secondState.flightDataTwoObject?.layover_operating_carrier_code} {secondState.flightDataTwoObject?.layover_flight_number}</span>
                  </div>
                </div>
                :
                basicState.basicDetailsObject?.returnType === "roundTrip" && state.flightDataOneObject?.layover === true && secondState.flightDataTwoObject?.layover === true ?
                  <div>
                    <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                      <span>{state.flightDataOneObject?.departing_at_date}</span>
                      <span>{state.flightDataOneObject?.origin_airport_code} to {state.flightDataOneObject?.layover_airport}</span>
                      <span>{state.flightDataOneObject?.layover_arrival}</span>
                      <span>{state.flightDataOneObject?.operating_carrier_code} {state.flightDataOneObject?.flight_number}</span>
                    </div>
                    <div className="border-solid border-2 rounded-lg border-sky-500 mt-2 mb-2">
                      <p className="text-center">
                        {state.flightDataOneObject?.layoverDiff} stop
                      </p>
                    </div>
                    <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                      <span>{state.flightDataOneObject?.layover_departure}</span>
                      <span>{state.flightDataOneObject?.layover_airport} to {state.flightDataOneObject?.destination_airport_code}</span>
                      <span>{state.flightDataOneObject?.arriving_at_date}</span>
                      <span>{state.flightDataOneObject?.layover_operating_carrier_code} {state.flightDataOneObject?.layover_flight_number}</span>
                    </div>
                    <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                      <span>{secondState.flightDataTwoObject?.departing_at_date}</span>
                      <span>{secondState.flightDataTwoObject?.origin_airport_code} to {secondState.flightDataTwoObject?.layover_airport}</span>
                      <span>{secondState.flightDataTwoObject?.layover_arrival}</span>
                      <span>{secondState.flightDataTwoObject?.operating_carrier_code} {secondState.flightDataTwoObject?.flight_number}</span>
                    </div>
                    <div className="border-solid border-2 rounded-lg border-sky-500 mt-2 mb-2">
                      <p className="text-center">
                        {secondState.flightDataTwoObject?.layoverDiff} stop
                      </p>
                    </div>
                    <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                      <span>{secondState.flightDataTwoObject?.layover_departure}</span>
                      <span>{secondState.flightDataTwoObject?.layover_airport} to {secondState.flightDataTwoObject?.destination_airport_code}</span>
                      <span>{secondState.flightDataTwoObject?.arriving_at_date}</span>
                      <span>{secondState.flightDataTwoObject?.layover_operating_carrier_code} {secondState.flightDataTwoObject?.layover_flight_number}</span>
                    </div>
                  </div>
                  :
                  <div />}





          {basicState.basicDetailsObject?.returnType === "oneWay" ?
            <div><div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span>{state.flightDataOneObject?.airline_name} {state.flightDataOneObject?.fare_type}</span>
              <div>{state.flightDataOneObject?.flight_duration}</div>
            </div>
              <div className="mt-4">
                <div>Fare basis code: {state.flightDataOneObject?.fare_basis_code}</div>
                <div className="mt-4" />
                <div>{fareRulesModal()}</div>

                {/* INDIVIDUAL FARE RULES */}
                <div className="mt-4" />
                {state.flightDataOneObject?.layover === false ?
                  <div>{staticFareRulesModal(state.flightDataOneObject?.origin_airport_code, state.flightDataOneObject?.destination_airport_code, indiFareRules())}</div> : <div />}
                {state.flightDataOneObject?.layover === true ? <div>
                  <div>{staticFareRulesModal(state.flightDataOneObject?.origin_airport_code, state.flightDataOneObject?.destination_airport_code, indiFareRules())}</div>
                  <div>{staticFareRulesModal(state.flightDataOneObject?.destination_airport_code, state.flightDataOneObject?.origin_airport_code, indiFareRules())}</div>
                </div> : <div />}
              </div></div> : <div />
          }

          {basicState.basicDetailsObject?.returnType === "roundTrip" ?
            <div><div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span>{state.flightDataOneObject?.airline_name} {state.flightDataOneObject?.fare_type}</span>
              <span>{secondState.flightDataTwoObject?.airline_name} {secondState.flightDataTwoObject?.fare_type}</span>
            </div>
              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <div>{state.flightDataOneObject?.flight_duration}</div>
                <div>{secondState.flightDataTwoObject?.flight_duration}</div>
              </div>
              <div className="mt-4">
                <div>Outbound fare basis code: {state.flightDataOneObject?.fare_basis_code}</div>
                <div>Inbound fare basis code: {secondState.flightDataTwoObject?.fare_basis_code}</div>
                <div className="mt-4" />
                {state.flightDataOneObject?.flight_number === '801' ? <div>This trip is a combination of separate one way tickets. <div>To view the full fare rules for the first leg, {fareRulesModal()}.</div> To view the full fare rules for the second leg, {fareRulesModal2()}</div> : <div>Your airline tickets are non-refundable and non-changeable. To view the full fare rules, {fareRulesModal()}.</div>}

                {/* INDIVIDUAL FARE RULES */}
                {/* <div className="mt-4" />
                {state.flightDataOneObject?.layover === false && secondState.flightDataTwoObject?.layover === false ?
                  <div>
                    <div>{staticFareRulesModal(state.flightDataOneObject?.origin_airport_code, state.flightDataOneObject?.destination_airport_code, indiFareRules())}</div>
                    <div>{staticFareRulesModal(secondState.flightDataTwoObject?.origin_airport_code, secondState.flightDataTwoObject?.destination_airport_code, indiFareRules())}</div>
                  </div> : <div />}
                {state.flightDataOneObject?.layover === true && secondState.flightDataTwoObject?.layover === false ?
                  <div>
                    <div>{staticFareRulesModal(state.flightDataOneObject?.origin_airport_code, state.flightDataOneObject?.layover_airport, indiFareRules())}</div>
                    <div>{staticFareRulesModal(state.flightDataOneObject?.layover_airport, state.flightDataOneObject?.destination_airport_code, indiFareRules())}</div>
                    <div>{staticFareRulesModal(secondState.flightDataTwoObject?.origin_airport_code, secondState.flightDataTwoObject?.destination_airport_code, indiFareRules())}</div>
                  </div> : <div />}
                {state.flightDataOneObject?.layover === false && secondState.flightDataTwoObject?.layover === true ?
                  <div>
                    <div>{staticFareRulesModal(state.flightDataOneObject?.origin_airport_code, state.flightDataOneObject?.destination_airport_code, indiFareRules())}</div>
                    <div>{staticFareRulesModal(secondState.flightDataTwoObject?.origin_airport_code, secondState.flightDataTwoObject?.layover_airport, indiFareRules())}</div>
                    <div>{staticFareRulesModal(secondState.flightDataTwoObject?.layover_airport, secondState.flightDataTwoObject?.destination_airport_code, indiFareRules())}</div>
                  </div> : <div />}
                {state.flightDataOneObject?.layover === true && secondState.flightDataTwoObject?.layover === true ?
                  <div>
                    <div>{staticFareRulesModal(state.flightDataOneObject?.origin_airport_code, state.flightDataOneObject?.layover_airport, indiFareRules())}</div>
                    <div>{staticFareRulesModal(state.flightDataOneObject?.layover_airport, state.flightDataOneObject?.destination_airport_code, indiFareRules())}</div>
                    <div>{staticFareRulesModal2(secondState.flightDataTwoObject?.origin_airport_code, secondState.flightDataTwoObject?.layover_airport, indiFareRules2())}</div>
                    <div>{staticFareRulesModal2(secondState.flightDataTwoObject?.layover_airport, secondState.flightDataTwoObject?.destination_airport_code, indiFareRules2())}</div>
                  </div> : <div />} */}
              </div>
            </div>
            : <div />
          }

          {/* {basicState.basicDetailsObject?.returnType === "oneWay" ?
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span>{state.flightDataOneObject?.operating_carrier_code} {state.flightDataOneObject?.flight_number}</span>
              <span>{state.flightDataOneObject?.flight_duration}</span>
            </div> : <div />
          }

          {basicState.basicDetailsObject?.returnType === "roundTrip" ?
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span>{state.flightDataOneObject?.airline_name} {state.flightDataOneObject?.fare_type}</span>
              <span>{state.flightDataOneObject?.flight_duration}</span>
              <span>{secondState.flightDataTwoObject?.airline_name} {secondState.flightDataTwoObject?.fare_type}</span>
              <span>{secondState.flightDataTwoObject?.flight_duration}</span>
            </div> : <div />
          } */}


          <div className="mt-4">
            This flight is covered by our free cancellation policy and is fully refundable as long as you cancel within 24 hours of booking. After that time, airline change penalties and restrictions apply.
          </div>

          {state.flightDataOneObject?.flight_number === '801' ? <><div>This flight includes a carry-on bag (no fee up to 10kg) and 2 checked bags (no fee up to 23kg each).</div><div>Seat choice is not available. Contact the airline directly after booking to choose your seat.</div><div>The change fee for this flight is $66.</div></> : <div />}


          <div className="mt-4">
            {state.flightDataOneObject?.flight_number === '801' ? <div>To view the full airline baggage policy rules, <a style={{ color: 'blue' }} href='https://www.royalairmaroc.com/us-en/baggage-information' target="_blank">click here</a></div> : <div>This fare includes a 25kg checked in bag and a 7kg cabin bag. For additional details on baggage allowances and seating options for the given rates, visit the airline(s) website.</div>}
          </div>

          <div className="mt-4">
            For additional policy details please refer to the airline(s) website.
          </div>

          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            {/* NUMBER OF PASSENGERS */}
            <span>Passengers</span>
            <span>{state.flightDataOneObject?.number_of_passengers}</span>
          </div>

          {/* NUMBER OF ADULTS */}
          <div>
            {state.flightDataOneObject?.number_of_adults != 0 ? <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span>Adults</span>
              <span>{state.flightDataOneObject?.number_of_adults}</span>
            </div> : <div>0</div>}
          </div>

          {/* NUMBER OF CHILDREN */}
          <div>
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span>Children</span>
              {state.flightDataOneObject?.number_of_children != 0 ?
                <span>{state.flightDataOneObject?.number_of_children}</span>
                : <div>0</div>}
            </div>
          </div>


          {/* NUMBER OF INFANTS */}
          <div>
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span>Infants</span>
              {state.flightDataOneObject?.number_of_infants != 0 ?
                <span>{state.flightDataOneObject?.number_of_infants}</span>
                : <div>0</div>}
            </div>
          </div>

          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          {/* USE THIS BORDER FOR RETURN FLIGHT STUFF */}
          {/* <div className="flex justify-between font-semibold">
            <span>Total Price incl. tax</span>
            <span>$57</span>
          </div> */}
        </div>
        <div className="flex flex-col space-y-4">
          <h3 className="text-2xl font-semibold">Price Details</h3>
          {basicState.basicDetailsObject?.returnType === "oneWay" ?
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span>Price excl. tax</span>
              <span>{Math.round(Number(state.flightDataOneObject?.flight_subtotal) * 0.55)} {state.flightDataOneObject?.flight_currency}</span>
            </div> : <div />
          }

          {basicState.basicDetailsObject?.returnType === "roundTrip" ?
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span>Price excl. tax</span>
              <span>{Math.round(Number(state.flightDataOneObject?.flight_subtotal) * 0.55) + Math.round(Number(secondState.flightDataTwoObject?.flight_subtotal) * 0.55)} {secondState.flightDataTwoObject?.flight_currency}</span>
            </div> : <div />
          }

          {basicState.basicDetailsObject?.returnType === "oneWay" ?
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span>Tax Amount</span>
              <span>{state.flightDataOneObject?.flight_tax_price} {state.flightDataOneObject?.flight_currency}</span>
            </div> : <div />
          }

          {basicState.basicDetailsObject?.returnType === "roundTrip" ?
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span>Tax Amount</span>
              <span>{Math.round(Number(state.flightDataOneObject?.flight_tax_price)) + Math.round(Number(secondState.flightDataTwoObject?.flight_tax_price))} {secondState.flightDataTwoObject?.flight_currency}</span>
            </div> : <div />
          }

          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

          {basicState.basicDetailsObject?.returnType === "oneWay" ?
            <div className="flex justify-between font-semibold">
              <span>Total Price incl. tax</span>
              <span>{state.flightDataOneObject?.flight_total_price} {state.flightDataOneObject?.flight_currency}</span>
            </div> : <div />
          }

          {basicState.basicDetailsObject?.returnType === "roundTrip" ?
            <div className="flex justify-between font-semibold">
              <span>Total Price incl. tax</span>
              <span>{Math.round(Number(state.flightDataOneObject?.flight_total_price)) + Math.round(Number(secondState.flightDataTwoObject?.flight_total_price))} {secondState.flightDataTwoObject?.flight_currency}</span>
            </div> : <div />
          }

        </div>
      </div>
    );
  };

  const renderMain = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">
          Confirmation and Payment
        </h2>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <div>
            {/* <h3 className="text-2xl font-semibold">Your trip</h3> */}
            {/* <NcModal
              renderTrigger={(openModal) => (
                <span
                  onClick={() => openModal()}
                  className="block lg:hidden underline  mt-1 cursor-pointer"
                >
                  View booking details
                </span>
              )}
              renderContent={renderSidebar}
            /> */}
          </div>
          {/* <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700">
            <div className="flex-1 p-5 flex justify-between space-x-5">
              <div className="flex flex-col">
                <span className="text-sm text-neutral-400">Date</span>
                <span className="mt-1.5 text-lg font-semibold">
                  Aug 12 - 16, 2021
                </span>
              </div>
              <PencilAltIcon className="w-6 h-6 text-neutral-300 dark:text-neutral-6000" />
            </div>
            <div className="flex-1 p-5 flex justify-between space-x-5">
              <div className="flex flex-col">
                <span className="text-sm text-neutral-400">Guests</span>
                <span className="mt-1.5 text-lg font-semibold">3 Guests</span>
              </div>
              <PencilAltIcon className="w-6 h-6 text-neutral-300 dark:text-neutral-6000" />
            </div>
          </div> */}
        </div>


        <div>
          <h3 className="text-2xl font-semibold">Enter Passenger Details</h3>
          <div className="mt-6">
            {state.flightDataOneObject?.number_of_passengers_array.map((item, index) => {
              return (
                <Tab.Group key={index}>
                  <div className="flex" >
                    <span className="mr-2.5 font-semibold">Passenger {index + 1} Details</span>
                  </div>
                  <div className="w-14 border-b border-neutral-200 my-5"></div>
                  <Tab.Panels>
                    <Tab.Panel className="space-y-5">
                      <div className="space-y-1">
                        <Input placeholder="First name" name={"name_" + index} onChange={(e) => { handleFirstName(e, index); setValidPassengerFirst(!false) }} />
                        {validPassengerFirst === false ? <div className="text-red-600">This field cannot be empty</div> : <div />}
                      </div>
                      <div className="space-y-1">
                        <Input placeholder="Last name" name={"name_" + index} onChange={(e) => { handleLastName(e, index); setValidPassengerLast(!false) }} />
                        {validPassengerLast === false ? <div className="text-red-600">This field cannot be empty</div> : <div />}

                      </div>
                      <div className="flex space-x-5  ">
                        {/* IF I CAN FIGURE OUT HOW TO DO A DROP DOWN MENU CAN ADD GENDER ETC */}
                      </div>
                    </Tab.Panel>
                  </Tab.Panels>
                </Tab.Group>
              )
            })}

          </div>
        </div>




        <div>
          <h3 className="text-2xl font-semibold">Enter Card Details</h3>
          <div className="mt-6">
            <Tab.Group>
              <div className="flex">
                <span className="mr-2.5 font-semibold">Debit/Credit Card</span>
                <span>
                  <img className="w-8 mr-2.5 mt-2" src={visaPng} alt="" />
                </span>
                <span>
                  <img className="w-8 mr-2.5" src={mastercardPng} alt="" />
                </span>
              </div>

              <div className="w-14 border-b border-neutral-200 my-5"></div>
              <Tab.Panels>
                <Tab.Panel className="space-y-5">
                  <div className="space-y-1">
                    <Label>Card Number</Label>
                    <Input placeholder="1111 2222 3333 4444"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>,): void => { setCardNumber(e.target.value); setValidCard(!false) }} />
                    {validCard === false ? <div className="text-red-600">Please enter a valid card number</div> : <div />}
                  </div>
                  <div className="space-y-1">
                    <Label>Cardholder Name</Label>
                    <Input placeholder="J. Doe"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>,): void => { setCardholderName(e.target.value); setValidCardholderName(!false) }} />
                    {validCardholderName === false ? <div className="text-red-600">This field cannot be empty</div> : <div />}
                  </div>
                  <div className="flex space-x-5  ">
                    <div className="flex-1 space-y-1">
                      <Label>Expiration Date</Label>
                      <Input type="month" placeholder="MM/YY"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>,): void => { setExpiryDate(e.target.value); setValidExpiryDate(!false) }} />
                      {validExpiryDate === false ? <div className="text-red-600">This field cannot be empty</div> : <div />}
                    </div>
                    <div className="flex-1 space-y-1">
                      <Label>CVV </Label>
                      <Input placeholder="555"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>,): void => { setCvv(e.target.value); setValidCvv(!false) }} />
                      {validCvv === false ? <div className="text-red-600">Please enter a valid CVV</div> : <div />}

                    </div>
                  </div>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </div>
        </div>

        <div>
          <h3 className="text-2xl font-semibold">Billing Information</h3>
          <div className="mt-6">
            <Tab.Group>
              <div className="w-14 border-b border-neutral-200 my-5"></div>
              <Tab.Panels>
                <Tab.Panel className="space-y-5">
                  <div className="space-y-1">
                    <Input placeholder="Street Address"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>,): void => { setStreetAddress(e.target.value); setValidStreetAddress(!false) }} />
                    {validStreetAddress === false ? <div className="text-red-600">This field cannot be empty</div> : <div />}
                  </div>
                  <div className="space-y-1">
                    {/* FIGURE OUT HOW TO GET DROP DOWN MENU AND LOAD WITH ARRAY OF COUNTRY NAMES */}
                    <Label>Country </Label>
                    <Input type="text" name="city" list="cityname"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>,): void => { setCountry(e.target.value); setValidCountry(!false) }} />
                    {validCountry === false ? <div className="text-red-600">This field cannot be empty</div> : <div />}
                    <datalist id="cityname">
                      {countryList.map((country, index) => {
                        return (<option value={country} key={index}></option>)
                      })}
                    </datalist>
                  </div>
                  <div className="flex space-x-5  ">
                    <div className="flex-1 space-y-1">
                      <Input placeholder="ZIP/Postal Code"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>,): void => { setZipCode(e.target.value); setValidZipCode(!false) }} />
                      {validZipCode === false ? <div className="text-red-600">This field cannot be empty</div> : <div />}
                    </div>
                    <div className="flex-1 space-y-1">
                      <Input placeholder="City"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>,): void => { setCity(e.target.value); setValidCity(!false) }} />
                      {validCity === false ? <div className="text-red-600">This field cannot be empty</div> : <div />}

                    </div>
                    <div className="flex-1 space-y-1">
                      <Input placeholder="Email Address"
                        type="email"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>,): void => { setEmail(e.target.value); setValidEmail(!false) }} />
                      {validEmail === false ? <div className="text-red-600">Please enter a valid e-mail address</div> : <div />}

                    </div>
                    <div className="flex-1 space-y-1">
                      <Input placeholder="Phone Number"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>,): void => { setPhone(e.target.value); setValidPhone(!false) }} />
                      {validPhone === false ? <div className="text-red-600">Please enter a valid phone number</div> : <div />}

                    </div>
                  </div>
                  {/* <div className="space-y-1">
                    <Label>Messager for author </Label>
                    <Textarea placeholder="..." />
                    <span className="text-sm text-neutral-500 block">
                      Write a few sentences about yourself.
                    </span>
                  </div> */}
                  {/* <div className="pt-4" onClick={handleSubmit}> */}
                  <ButtonPrimary loading={clicked ? true : false} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleSubmit(e)}
                  // disabled={allValid ? false : true}
                  >Confirm and Pay</ButtonPrimary>
                  {/* </div> */}
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </div>
        </div>

      </div>
    );
  };

  const fareRulesModal = () => {
    return (
      <>
        <a style={{ color: 'blue' }} onClick={handleClickOpen('paper')}>click here</a>
        <Dialog
          open={open}
          onClose={handleClose}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">Full Fare Rules</DialogTitle>
          <DialogContent dividers={scroll === 'paper'}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              {fareRulesText()}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }

  const fareRulesModal2 = () => {
    return (
      <>
        <a style={{ color: 'blue' }} onClick={handleClickOpen2('paper')}>click here</a>
        <Dialog
          open={open2}
          onClose={handleClose2}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">Full Fare Rules</DialogTitle>
          <DialogContent dividers={scroll === 'paper'}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef2}
              tabIndex={-1}
            >
              {fareRulesText2()}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose2}>Close</Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }

  const staticFareRulesModal = (origin: string | undefined, destination: string | undefined, fareRules: any) => {
    return (
      <>
        <a style={{ color: 'blue' }} onClick={handleClickOpen3('paper')}>View fare rules for {origin} - {destination}</a>
        <Dialog
          open={open3}
          onClose={handleClose3}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">Fare Rules</DialogTitle>
          <DialogContent dividers={scroll === 'paper'}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              {fareRules}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose3}>Close</Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }

  const staticFareRulesModal2 = (origin: string | undefined, destination: string | undefined, fareRules2: any) => {
    return (
      <>
        <a style={{ color: 'blue' }} onClick={handleClickOpen4('paper')}>View fare rules for {origin} - {destination}</a>
        <Dialog
          open={open4}
          onClose={handleClose4}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">Fare Rules</DialogTitle>
          <DialogContent dividers={scroll === 'paper'}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              {fareRules2}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose4}>Close</Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }

  return (
    <div className={`nc-CheckOutPage ${className}`} data-nc-id="CheckOutPage">
      <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
        <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">{renderMain()}</div>
        <div className="hidden lg:block flex-grow">{renderSidebar()}</div>
      </main>
    </div>
  );
};

export default CheckOutPage;
