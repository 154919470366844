const fareRulesText2 = () => {
    return (
        <div>
            <p>From: Lagos, Nigeria (LOS-Murtala Muhammed Intl.)<br />
                To: London, England, UK (LHR-Heathrow)<br />
                Fare Basis Code: MA0RAEUA<br />
                (6)MN.MIN STAY</p>

            <p>NONE UNLESS OTHERWISE SPECIFIED</p>

            <p>(7)MX.MAX STAY</p>

            <p>TRAVEL FROM LAST STOPOVER MUST COMMENCE NO LATER THAN 12 MONTHS AFTER DEPARTURE FROM FARE ORIGIN.</p>

            <p>(3)SE.SEASONS</p>

            <p>APPLIES ALL YEAR</p>

            <p>(11)BO.BLACKOUTS</p>

            <p>NONE</p>

            <p>(2)DA.DAY/TIME</p>

            <p>NO RESTRICTIONS</p>

            <p>(15)SR.SALES RESTRICT</p>

            <p>FROM/TO LOS IF THE FARE COMPONENT IS ON ONE OR MORE OF THE FOLLOWING ANY AT FLIGHT OPERATED BY AT TICKETS MUST BE ISSUED ON AT AND MAY NOT BE SOLD IN EGYPT/RUSSIA EXTENSION OF TICKET VALIDITY IS NOT PERMITTED. OR - TICKETS MUST BE ISSUED ON AT OR HR AND MAY NOT BE SOLD IN EGYPT/RUSSIA OR - TICKETS MUST BE ISSUED ON AT OR W2 AND MAY NOT BE SOLD IN EGYPT/RUSSIA OR - TICKETS MUST BE ISSUED ON AT OR GP AND MAY NOT BE SOLD IN EGYPT/RUSSIA OR - TICKETS MUST BE ISSUED ON AT OR HF AND MAY NOT BE SOLD IN EGYPT/RUSSIA IF THE FARE COMPONENT IS ON ONE OR MORE OF THE FOLLOWING AT FLIGHTS 5000 THROUGH 5999 AT FLIGHTS 9000 THROUGH 9999 TICKETS MUST BE ISSUED ON AT AND MAY NOT BE SOLD IN EGYPT/RUSSIA</p>

            <p>(14)TR.TVL RESTRICTION</p>

            <p>NO RESTRICTIONS</p>

            <p>(5)AP.ADVANCE RES/TKT</p>

            <p>RESERVATIONS ARE REQUIRED FOR ALL SECTORS.</p>

            <p>(4)FL.FLT APPLICATION</p>

            <p>THE FARE COMPONENT MUST BE ON ONE OR MORE OF THE FOLLOWING ANY AT FLIGHT OPERATED BY AT.</p>

            <p>(19)CD.CHILD DISCOUNTS</p>

            <p>ACCOMPANIED CHILD 2-11 - CHARGE 75 PERCENT OF THE FARE. TICKETING CODE - BASE FARE CODE PLUS CH. MUST BE ACCOMPANIED ON ALL FLIGHTS IN SAME COMPARTMENT BY ADULT 18 OR OLDER OR - INFANT UNDER 2 WITH A SEAT - CHARGE 75 PERCENT OF THE FARE. TICKETING CODE - BASE FARE CODE PLUS INS. MUST BE ACCOMPANIED ON ALL FLIGHTS IN SAME COMPARTMENT BY ADULT 18 OR OLDER OR - 1ST INFANT UNDER 2 WITHOUT A SEAT - CHARGE 10 PERCENT OF THE FARE. TICKETING CODE - BASE FARE CODE PLUS INF. MUST BE ACCOMPANIED ON ALL FLIGHTS IN SAME COMPARTMENT BY ADULT 18 OR OLDER OR - UNACCOMPANIED CHILD 4-14 - CHARGE 100 PERCENT OF THE FARE PLUS EUR 50.00. TICKETING CODE - BASE FARE CODE PLUS UM.</p>

            <p>(20)TC.TOUR CONDUCTOR</p>

            <p>DISCOUNT DOES NOT APPLY</p>

            <p>(21)AD.AGTS DISCOUNTS</p>

            <p>NONE UNLESS OTHERWISE SPECIFIED</p>

            <p>(22)OD.OTHER DISCOUNTS</p>

            <p>CONTACT CARRIER FOR FURTHER INFOS</p>

            <p>(8)SO.STOPOVERS</p>

            <p>FOR MA0RAEUA TYPE FARES NOTE - GENERAL RULE DOES NOT APPLY 2 FREE STOPOVERS PERMITTED ON THE PRICING UNIT - 1 IN EACH DIRECTION. A STOPOVER MAY NOT EXCEED 21 DAYS.</p>

            <p>(9)TF.TRANSFERS/RTGS</p>

            <p>UNLIMITED TRANSFERS PERMITTED ON THE PRICING UNIT FARE BREAK SURFACE SECTORS NOT PERMITTED AND EMBEDDED SURFACE SECTORS PERMITTED ON THE FARE COMPONENT.</p>

            <p>(12)SU.SURCHARGES</p>

            <p>BETWEEN LON AND LOS FOR MA0RAEUA TYPE FARES THE PROVISIONS BELOW APPLY ONLY AS FOLLOWS - SALE IS RESTRICTED TO SPECIFIC AGENTS. ORIGINATING LON - A SURCHARGE OF 25 PERCENT OF THE FARE PER TICKET WILL BE ADDED TO THE APPLICABLE FARE FOR TRAVEL. AND - A SURCHARGE OF 5 PERCENT OF THE FARE PER TICKET WILL BE ADDED TO THE APPLICABLE FARE FOR TRAVEL. A SURCHARGE OF 5 PERCENT OF THE FARE PER TICKET WILL BE ADDED TO THE APPLICABLE FARE FOR TRAVEL.</p>

            <p>(1)EL.ELIGIBILITY</p>

            <p>NO RESTRICTIONS</p>

            <p>(13)AC.ACCOMPANY TVL</p>

            <p>NO RESTRICTIONS</p>

            <p>(18)TE.TKT ENDORSEMENT</p>

            <p>TICKET ENDORSEMENTS ARE ALLOWED, CHECK WITH ISSUING CARRIER</p>

            <p>(16)PE.PENALTIES</p>

            <p>FOR MA0RAEUA TYPE FARES CANCELLATIONS BEFORE DEPARTURE CHARGE EUR 280.00 FOR REFUND. CHILD/INFANT DISCOUNTS APPLY. CHARGE EUR 280.00 FOR NO-SHOW. CHILD/INFANT DISCOUNTS APPLY. AFTER DEPARTURE TICKET IS NON-REFUNDABLE IN CASE OF REFUND. TICKET IS NON-REFUNDABLE IN CASE OF NO-SHOW. NOTE - THE YQ REFUND FOLLOWS THE SAME CONDITIONS OF THE APPLICABLE FARE. CHANGES ANY TIME PER DIRECTION CHARGE EUR 60.00 FOR REISSUE/ REVALIDATION. PER DIRECTION CHARGE EUR 110.00 FOR NO-SHOW. NOTE - UPGRADE TO HIGHER FARE MUST BE TO THE HIGHER AVAILABLE CLASS IN THE FLIGHT DATE DISPLAY.</p>

            <p>(10)CO.COMBINABILITY</p>

            <p>APPLICABLE ADD-ON CONSTRUCTION IS ADDRESSED IN MISCELLANEOUS PROVISIONS - CATEGORY 23. END-ON-END END-ON-END COMBINATIONS PERMITTED WITH DOMESTIC FARES. VALIDATE ALL FARE COMPONENTS. FARES MUST BE SHOWN SEPARATELY ON THE TICKET. TRAVEL MUST BE VIA THE POINT OF COMBINATION. SIDE TRIPS PERMITTED. OPEN JAWS/ROUND TRIPS/CIRCLE TRIPS FARES MAY BE COMBINED ON A HALF ROUND TRIP BASIS -TO FORM SINGLE OR DOUBLE OPEN JAWS/ROUND TRIPS/CIRCLE TRIPS. PROVIDED - COMBINATIONS ARE WITH ANY FARE FOR CARRIER AT IN ANY RULE AND TARIFF.</p>

            <p>(17)HI.HIGHER INTERMEDIATE POINT</p>

            <p>DOES NOT APPLY</p>

            <p>(29)DE.DEPOSITS</p>

            <p>NO RESTRICTIONS</p>

            <p>(26)GP.GROUPS</p>

            <p>DOES NOT APPLY</p>

            <p>(27)TO.TOURS</p>

            <p>DOES NOT APPLY</p>

            <p>(28)VI.VISIT ANOTHER COUNTRY</p>

            <p>DOES NOT APPLY</p>

            <p>(23)MD.MISCELLANEOUS DATA</p>

            <p>THIS FARE MUST NOT BE USED AS THE HIGH OR THE LOW FARE WHEN CALCULATING A DIFFERENTIAL. THIS FARE MUST NOT BE USED AS THE THROUGH FARE WHEN PRICING A FARE COMPONENT WITH A DIFFERENTIAL.</p>

            <p>(31)VC.VOLUNTARY CHANGES</p>

            <p>VOLUNTARY CHANGES CONDITIONS MAY APPLY FOR AUTOMATED REISSUE/REVALIDATION REFER TO PENALTIES CATEGORY *PE FOR DETAILS</p>

            <p>(33)VR.VOLUNTARY REFUNDS</p>

            <p>VOLUNTARY CHANGES CONDITIONS MAY APPLY FOR AUTOMATED REFUNDS REFER TO PENALTIES CATEGORY *PE FOR DETAILS<br />
            </p>
        </div>
    )
}

export default fareRulesText2;