import { ActionType } from "state/action-types"
import { Dispatch } from "redux"
import { Action, PossibleOfferRequestObject, PossibleOfferObject, PossibleFlightDataOneObject, PossibleFlightDataTwoObject, PossibleCustomerDataObject, PossibleBasicDetailsObject, PossibleLoginData } from "state/actions/index"

export const getOfferRequest = (data: PossibleOfferRequestObject) => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.GET_OFFER_REQUEST,
            payload: data
        })
    }
}

export const getOffers = (data: PossibleOfferObject) => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.GET_OFFERS,
            payload: data
        })
    }
}

export const getReturnOffers = (data: PossibleOfferObject) => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.GET_RETURN_OFFERS,
            payload: data
        })
    }
}

export const storeFlightDataOne = (data: PossibleFlightDataOneObject) => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.STORE_FLIGHT_DATA_ONE,
            payload: data
        })
    }
}

export const storeFlightDataTwo = (data: PossibleFlightDataOneObject) => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.STORE_FLIGHT_DATA_TWO,
            payload: data
        })
    }
}

export const storeCustomerData = (data: PossibleCustomerDataObject) => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.STORE_CUSTOMER_DATA,
            payload: data
        })
    }
}

export const getBasicDetails = (data: PossibleBasicDetailsObject) => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.GET_BASIC_DETAILS,
            payload: data
        })
    }
}

export const storeLogin = (data: PossibleLoginData) => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.STORE_LOGIN,
            payload: data
        })
    }
}

export const cancelFlight = (data: number) => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.CANCEL_FLIGHT,
            payload: data
        })
    }
}

export const changeCurrency = (data: string) => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.CHANGE_CURRENCY,
            payload: data
        })
    }
}

export const staticFlightReturnOne = (data: boolean) => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.STATIC_FLIGHT_RETURN_ONE,
            payload: data
        })
    }
}


export const staticFlightReturnTwo = (data: boolean) => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.STATIC_FLIGHT_RETURN_TWO,
            payload: data
        })
    }
}

export const setLoad = (data: boolean) => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.SET_LOAD,
            payload: data
        })
    }
}