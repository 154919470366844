import React, { FC, useState } from "react";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SocialsList from "shared/SocialsList/SocialsList";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import emailjs from 'emailjs-com';

export interface PageContactProps {
  className?: string;
}

const info = [
  // {
  //   title: "🗺 ADDRESS",
  //   desc: "Photo booth tattooed prism, portland taiyaki hoodie neutra typewriter",
  // },

  {
    title: "💌 EMAIL",
    desc: "help@travel7world.com",
  },
  {
    title: "☎ PHONE",
    desc: "+1 (661) 493 8483",
  },
];

const PageContact: FC<PageContactProps> = ({ className = "" }) => {

  var emailRegex = "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$"
  const [validEmail, setValidEmail] = useState<boolean>()
  const [email, setEmail] = useState('')



  const sendEmail = (e: any) => {

    if (email.match(emailRegex)) {
      setValidEmail(true)
      emailjs.sendForm('service_vm38wff', 'template_jx3ozmt', e.target, '1drt90QGDzO3W0qz0')
        .then((result) => {
          console.log(result.text)
        }, (error) => {
          console.log(error.text)
        });
      e.target.reset()
      alert("We have recieved your message and will endeavour to get back to you as soon as possible!")
    } else {
      setValidEmail(false)
    }

    e.preventDefault();
  }


  return (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      <Helmet>
        <title>Contact || Travel 7 World</title>
      </Helmet>
      <div className="mb-24 lg:mb-32">
        <h2 className="my-16 sm:my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Contact
        </h2>
        <div className="container max-w-7xl mx-auto">
          <div className="flex-shrink-0 grid grid-cols-1 sm:grid-cols-2 gap-12 ">
            <div className="max-w-sm space-y-8">
              {info.map((item, index) => (
                <div key={index}>
                  <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                    {item.title}
                  </h3>
                  <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    {item.desc}
                  </span>
                </div>
              ))}
              {/* <div>
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  🌏 SOCIALS
                </h3>
                <SocialsList className="mt-2" />
              </div> */}
            </div>
            <div>
              <form className="grid grid-cols-1 gap-6" onSubmit={(e) => sendEmail(e)}>
                <label className="block">
                  <Label>Full Name</Label>

                  <Input
                    placeholder="J Smith"
                    type="text"
                    className="mt-1"
                    name="name"
                  />
                </label>
                <label className="block">
                  <Label>Email Address</Label>

                  <Input
                    type="email"
                    placeholder="jsmith@example.com"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>,): void => { setEmail(e.target.value); setValidEmail(!false) }}
                    className="mt-1"
                    name="email"
                  />
                  {validEmail === false ? <div className="text-red-600">Please enter a valid e-mail address</div> : <div />}
                </label>
                <label className="block">
                  <Label>Message</Label>

                  <Textarea className="mt-1" rows={6} name="message" />
                </label>
                <div>
                  <ButtonPrimary type="submit">Send Message</ButtonPrimary>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* OTHER SECTIONS */}
      {/* <div className="container">
        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="Pagecontact_" />
        </div>
        <SectionSubscribe2 className="py-24 lg:py-32" />
      </div> */}
    </div>
  );
};

export default PageContact;
