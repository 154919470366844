import React, { FC, useState, useEffect } from "react";
import { parse, end, toSeconds, pattern } from "iso8601-duration";
import dateFormat, { masks } from "dateformat";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux"
import { bindActionCreators } from "redux";
import { actionCreators, State } from "state";
import { Offer } from "dataTypes/dataTypes";

export interface FlightCardProps {
  className?: string;
  returnType?: string;
  flightData: Offer
}

export interface Duration {
  years?: number | string,
  months?: number | string,
  weeks?: number | string,
  days?: number | string,
  hours?: number | string,
  minutes?: number | string,
  seconds?: number | string
}

const StaticFlightCardReturnAgain = ({ className = "" }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [flightDataOneObject, setFlightDataOneObject] = useState({});

  const [layover, setLayover] = useState(true);


  const history = useHistory();

  const dispatch = useDispatch();
  const { staticFlightReturnTwo } = bindActionCreators(actionCreators, dispatch);
  const { storeFlightDataTwo } = bindActionCreators(actionCreators, dispatch);
  const state = useSelector((state: State) => state.storeFlightDataOneReducer)

  const formatDuration = (duration: Duration) => {

    var years = duration.years
    var months = duration.months
    var weeks = duration.weeks
    var days = duration.days
    var hours = duration.hours
    var minutes = duration.minutes
    var seconds = duration.seconds

    if (years == "0") {
      years = ""
    } else {
      years = years + " years "
    }

    if (months == "0") {
      months = ""
    } else {
      months = months + " months "
    }

    if (weeks == "0") {
      weeks = ""
    } else {
      weeks = weeks + " weeks "
    }

    if (days == "0") {
      days = ""
    } else {
      days = days + " day "
    }

    if (hours == "0") {
      hours = ""
    } else {
      hours = hours + " hours "
    }

    if (minutes == "0") {
      minutes = ""
    } else {
      minutes = minutes + " minutes "
    }

    if (seconds == "0") {
      seconds = ""
    } else {
      seconds = seconds + " seconds "
    }

    return years + months + weeks + days + hours + minutes + seconds
  }

  var arriving_at_time = ""
  var arriving_at_simple_time = ""
  var layover_origin = "";
  var layover_airport = "";
  var layover_arrival = "";
  var layover_departure = "";
  var arriving_at_date = "";
  var layover_city = "";
  var layoverDiff = "";
  var layover_operating_carrier_code = "";
  var layover_flight_number = "";
  var layover_aircraft_name = "Airbus A321";
  var aircraft_name = "Airbus A321";


  const formatDates = (d1: Date, d2: Date) => {
    var diff = Math.abs((d1).getTime() - (d2).getTime()) / 36e5
    var hours = Math.floor(diff);
    var minutes = Math.round((diff - hours) * 60)

    if (hours > 0 && minutes > 0) {
      return hours.toString() + " hours and " + minutes.toString() + " minutes"
    } else if (hours === 0 && minutes > 0) {
      return minutes.toString() + " minutes"
    } else {
      return hours.toString() + " hours"
    }
  }


  // if (layover == true) {
  //   layover_origin = flightData.slices[0].segments[0].destination.name
  //   layover_airport = flightData.slices[0].segments[0].destination.iata_code

  // }

  var airline_logo = "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/EY.svg"
  var departing_at_date = "November 25th, 2024 6:15 PM"
  var origin_airport_name = "Melbourne Airport"
  var origin_airport_code = "MEL"
  var destination_airport_name = "Heathrow Airport"
  var destination_airport_code = "LHR"
  var flight_duration = "23 hours 30 minutes"
  var operating_carrier_code = "EY"
  var fare_type = "Standard Economy"
  var aircraft_name = "Boeing 787";

  var flight_number = "461";
  var departing_at_time = "November 25th, 2024 6:15 PM";
  var fare_basis_code = "ELN18V2R";
  if (layover == true) {
    arriving_at_time = ""
    arriving_at_simple_time = "6:45 AM";
    layover_origin = "Zayed International Airport";
    layover_airport = "AUH";
    layover_arrival = "November 26th, 2024 1:20 AM";
    layover_departure = "November 26th, 2024 2:40 AM";
    arriving_at_date = "November 26th, 2024 6:45 AM";
    layover_city = "AUH";
    layoverDiff = "1 hour 20 minutes";
    layover_operating_carrier_code = "EY";
    layover_flight_number = "61";
    layover_aircraft_name = "Airbus A380";
  } else {
    arriving_at_time = "";
    arriving_at_simple_time = "";
    arriving_at_date = "";
  }
  var departing_at_simple_time = "6:15 AM";
  var flight_subtotal = "500";
  var flight_tax_price = 156 * 0.55;
  var flight_total_price = 656 * 0.55;

  var flight_currency = "USD";
  var number_of_passengers = 1;
  var number_of_passengers_array = [{}];
  var number_of_adults = 1;
  var number_of_children = 0;
  var number_of_infants = 0;
  var origin_city = "Melbourne";
  var destination_city = "London";
  var airline_name = "Etihad Airlines";







  var flightObjectOne = {
    airline_logo: airline_logo,
    departing_at_date: departing_at_date,
    origin_airport_name: origin_airport_name,
    origin_airport_code: origin_airport_code,
    arriving_at_date: arriving_at_date,
    destination_airport_name: destination_airport_name,
    destination_airport_code: destination_airport_code,
    flight_duration: flight_duration,
    operating_carrier_code: operating_carrier_code,
    fare_type: fare_type,
    aircraft_name: aircraft_name,
    flight_number: flight_number,
    departing_at_time: departing_at_time,
    arriving_at_time: arriving_at_time,
    departing_at_simple_time: departing_at_simple_time,
    arriving_at_simple_time: arriving_at_simple_time,
    flight_subtotal: flight_subtotal,
    flight_tax_price: flight_tax_price,
    flight_total_price: flight_total_price,
    flight_currency: flight_currency,
    number_of_passengers: number_of_passengers,
    number_of_passengers_array: number_of_passengers_array,
    number_of_adults: number_of_adults,
    number_of_children: number_of_children,
    number_of_infants: number_of_infants,
    origin_city: origin_city,
    destination_city: destination_city,
    airline_name: airline_name,
    return_type: "roundTrip",
    layover: layover,
    layover_origin: layover_origin,
    layover_airport: layover_airport,
    layover_arrival: layover_arrival,
    layover_departure: layover_departure,
    layover_city: layover_city,
    layover_operating_carrier_code: layover_operating_carrier_code,
    layover_flight_number: layover_flight_number,
    layoverDiff: layoverDiff,
    fare_basis_code: fare_basis_code
  };



  const handleSubmit = () => {

    storeFlightDataTwo(flightObjectOne)
    staticFlightReturnTwo(true)


    history.push('/checkout')

  }

  const renderOneWay = () => {
    return (
      <div className="p-4 md:p-8 border border-neutral-200 dark:border-neutral-700 rounded-2xl ">
        <div>
          <div className="flex flex-col md:flex-row ">
            <div className="w-24 md:w-20 lg:w-24 flex-shrink-0 md:pt-7">
              <img src={airline_logo} className="w-10" alt="" />
            </div>
            <div className="flex my-5 md:my-0">
              <div className="flex-shrink-0 flex flex-col items-center py-2">
                <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
                <span className="block flex-grow border-l border-neutral-400 border-dashed my-1"></span>
                <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
              </div>
              <div className="ml-4 space-y-10 text-sm">
                <div className="flex flex-col space-y-1">
                  <span className=" text-neutral-500 dark:text-neutral-400">
                    {departing_at_date}
                  </span>
                  <span className=" font-semibold">
                    {origin_airport_name} {(origin_airport_code)}
                  </span>
                </div>
                <div className="flex flex-col space-y-1">
                  <span className=" text-neutral-500 dark:text-neutral-400">
                    {arriving_at_date}
                  </span>
                  <span className=" font-semibold">
                    {destination_airport_name} {(destination_airport_code)}
                  </span>
                </div>
              </div>
            </div>
            <div className="border-l border-neutral-200 dark:border-neutral-700 md:mx-6 lg:mx-10"></div>
            <ul className="text-sm text-neutral-500 dark:text-neutral-400 space-y-1 md:space-y-2">
              <li>Trip time: {flight_duration}</li>
              <li>{operating_carrier_code} {flight_number} · {fare_type} · {aircraft_name}</li>
              <div>Fare Basis Code: {fare_basis_code}</div>
            </ul>
            <div className="border-l border-neutral-200 dark:border-neutral-700 md:mx-6 lg:mx-10">
              <div className="text-sm text-neutral-500 dark:text-neutral-400 space-y-1 md:space-y-2 ml-4">
                {airline_name}
              </div>
            </div>
            <span className="ml-40" >
              <ButtonPrimary onClick={handleSubmit}>
                CHOOSE RETURN FLIGHT
              </ButtonPrimary>
            </span>
          </div>
        </div>
      </div>
    );
  };

  const middlePart = () => {
    return (
      <div className="p-4 md:p-8 border border-neutral-200 dark:border-neutral-700 rounded-2xl ">
        <div className="my-7 md:my-10 space-y-5 md:pl-24">
          <div className="border-t border-neutral-200 dark:border-neutral-700" />
          <div className="text-neutral-700 dark:text-neutral-300 text-sm md:text-base">
            Transit time: 15 hours 45 minutes - Bangkok (BKK)
          </div>
          <div className="border-t border-neutral-200 dark:border-neutral-700" />
        </div>

      </div>
    )
  }

  const renderLayover = () => {
    return (
      <div>
        {/* {middlePart()} */}
        <div className="p-4 md:p-8 border border-neutral-200 dark:border-neutral-700 rounded-2xl ">
          <div>
            <div className="flex flex-col md:flex-row ">
              <div className="w-24 md:w-20 lg:w-24 flex-shrink-0 md:pt-7">
                <img src={airline_logo} className="w-10" alt="" />
              </div>
              <div className="flex my-5 md:my-0">
                <div className="flex-shrink-0 flex flex-col items-center py-2">
                  <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
                  <span className="block flex-grow border-l border-neutral-400 border-dashed my-1"></span>
                  <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
                </div>
                <div className="ml-4 space-y-10 text-sm">
                  <div className="flex flex-col space-y-1">
                    <span className=" text-neutral-500 dark:text-neutral-400">
                      {departing_at_date}
                    </span>
                    <span className=" font-semibold">
                      {origin_airport_name} {(origin_airport_code)}
                    </span>
                  </div>
                  {/* LAYOVER PART */}
                  <div className="flex flex-col space-y-1">
                    <span className=" text-neutral-500 dark:text-neutral-400">
                      {layover_arrival}
                    </span>
                    <span className=" font-semibold">
                      {layover_origin} {(layover_airport)}
                    </span>
                    <span className=" text-neutral-500 dark:text-neutral-400">
                      {layover_departure}
                    </span>
                  </div>
                  <div className="flex flex-col space-y-1">
                    <span className=" text-neutral-500 dark:text-neutral-400">
                      {arriving_at_date}
                    </span>
                    <span className=" font-semibold">
                      {destination_airport_name} {(destination_airport_code)}
                    </span>
                  </div>
                </div>
              </div>
              <div className="border-l border-neutral-200 dark:border-neutral-700 md:mx-6 lg:mx-10"></div>
              <ul className="text-sm text-neutral-500 dark:text-neutral-400 space-y-1 md:space-y-2">
                <li>Trip time: {flight_duration}</li>
                {layover === true ? <div><li>{operating_carrier_code} {flight_number} · {fare_type} · {aircraft_name}</li>
                  <li className="mt-8">{layover_operating_carrier_code} {layover_flight_number} · {fare_type} · {layover_aircraft_name}</li>
                </div> :
                  <div><li>{operating_carrier_code} {flight_number} · {fare_type} · {aircraft_name}</li>
                  </div>}
                <div>Fare Basis Code: {fare_basis_code}</div>

              </ul>
              <div className="border-l border-neutral-200 dark:border-neutral-700 md:mx-6 lg:mx-10">
                <div className="text-sm text-neutral-500 dark:text-neutral-400 space-y-1 md:space-y-2 ml-4">
                  {airline_name}
                </div>
              </div>
              <span className="ml-40" >
                <ButtonPrimary onClick={handleSubmit}>
                  BOOK NOW
                </ButtonPrimary>
              </span>
            </div>
          </div>
        </div>
      </div>

    );
  };

  const renderDetail = () => {
    if (!isOpen) return null;
    return (
      // <div className="p-4 md:p-8 border border-neutral-200 dark:border-neutral-700 rounded-2xl ">
      //   {renderDetailTop()}
      //   <div className="my-7 md:my-10 space-y-5 md:pl-24">
      //     <div className="border-t border-neutral-200 dark:border-neutral-700" />
      //     <div className="text-neutral-700 dark:text-neutral-300 text-sm md:text-base">
      //       Transit time: 15 hours 45 minutes - Bangkok (BKK)
      //     </div>
      //     <div className="border-t border-neutral-200 dark:border-neutral-700" />
      //   </div>

      // </div>
      <div>
        {layover == true ? renderLayover() : renderOneWay()}

      </div>
    );
  };

  return (
    <div
      className={`nc-FlightCardgroup p-4 sm:p-6 relative bg-white dark:bg-neutral-900 border border-neutral-100
     dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-lg transition-shadow space-y-6 ${className}`}
      data-nc-id="FlightCard"
    >
      <div
        className={` sm:pr-20 relative  ${className}`}
        data-nc-id="FlightCard"
      >
        {/*  eslint-disable-next-line jsx-a11y/anchor-has-content */}
        {/* <a href="##" className="absolute inset-0" /> */}

        <span
          className={`absolute right-0 bottom-0 sm:bottom-auto sm:top-1/2 sm:-translate-y-1/2 w-10 h-10 bg-neutral-50 dark:bg-neutral-800 rounded-full flex items-center justify-center cursor-pointer ${isOpen ? "transform -rotate-180" : ""
            }`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <i className="text-xl las la-angle-down"></i>
        </span>

        <div className="flex  flex-col sm:flex-row sm:items-center space-y-6 sm:space-y-0">
          {/* LOGO IMG */}
          <div className="w-24 lg:w-32 flex-shrink-0">
            <img src={airline_logo} className="w-10" alt="" />
          </div>

          {/* FOR MOBILE RESPONSIVE */}
          <div className="block lg:hidden space-y-1">
            <div className="flex font-semibold">
              <div>
                {departing_at_time}
                <span></span>
                <span className="flex items-center text-sm text-neutral-500 font-normal mt-0.5">
                  {origin_airport_code}
                </span>
              </div>
              <span className="w-12 flex justify-center">
                <i className=" text-2xl las la-long-arrow-alt-right"></i>
              </span>
              <div>
                <span>
                  {arriving_at_time}
                </span>
                <span className="flex items-center text-sm text-neutral-500 font-normal mt-0.5">
                  {destination_airport_code}
                </span>
              </div>
            </div>

            <div className="text-sm text-neutral-500 font-normal mt-0.5">
              <span className="VG3hNb">Nonstop</span>
              <span className="mx-2">·</span>
              <span>{flight_duration}</span>
              <span className="mx-2">·</span>
              <span>HAN</span>
            </div>
          </div>

          {/* TIME - NAME */}
          <div className="hidden lg:block  min-w-[150px] flex-[4] ">
            <div className="font-medium text-lg">
              {departing_at_simple_time} - {arriving_at_simple_time}
            </div>
            <div className="text-sm text-neutral-500 font-normal mt-0.5">
              {/* {data.airlines.name} */}
            </div>
          </div>

          {/* TIMME */}
          <div className="hidden lg:block flex-[4] whitespace-nowrap">
            <div className="font-medium text-lg">{origin_airport_code} - {destination_airport_code}</div>
            <div className="text-sm text-neutral-500 font-normal mt-0.5">
              {flight_duration}
            </div>
          </div>

          {/* TYPE // LAYOVER TYPE SHIT */}
          <div className="hidden lg:block flex-[4] whitespace-nowrap">
            {layover == true ? <div className="font-medium text-lg">1 stop</div> : <div className="font-medium text-lg">Direct</div>}
            {/* <div className="font-medium text-lg">1 stop</div> */}
            <div className="text-sm text-neutral-500 font-normal mt-0.5">
              {/* LAYOVER TIME HERE PLS 29/07 */}
              {layover == true ? <div className="font-medium text-lg">{layoverDiff} stop</div> : <div />}
            </div>
          </div>

          {/* PRICE */}
          <div className="flex-[4] whitespace-nowrap sm:text-right">
            <div>
              <span className="text-xl font-semibold text-secondary-6000">
                {flight_total_price} {flight_currency}
              </span>
            </div>
            <div className="text-xs sm:text-sm text-neutral-500 font-normal mt-0.5">
              round-trip
            </div>
          </div>
        </div>
      </div>

      {/* DETAIL */}
      {renderDetail()}
    </div>
  );
};

export default StaticFlightCardReturnAgain;
