import React, { FC, useState } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link, useHistory } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface PageLoginProps {
  className?: string;
}

const loginSocials = [
  {
    name: "Continue with Facebook",
    href: "#",
    icon: facebookSvg,
  },
  {
    name: "Continue with Twitter",
    href: "#",
    icon: twitterSvg,
  },
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg,
  },
];

const ForgotPass: FC<PageLoginProps> = ({ className = "" }) => {

  const history = useHistory();
  const [validEmail, setValidEmail] = useState<boolean>()



  const [email, setEmail] = useState('')

  const submission = async (e: { preventDefault: () => void; }) => {

    e.preventDefault()

    if (email === '') {
      setValidEmail(false)
    } else {
      setValidEmail(true)
    }

    if (validEmail === true) {
      alert("Your request has been submitted successfully, if a matching account is found, you will recieve a password reminder to your email.")

      history.push('/')
    }


  }

  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Forgot Password || Travel 7 World</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Forgot Password
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          <div className="grid gap-3">
          </div>
          <form className="grid grid-cols-1 gap-6" onSubmit={submission}>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email address
              </span>
              <Input
                type="email"
                placeholder=""
                className="mt-1"
                value={email}
                onChange={(e) => { setEmail(e.target.value); setValidEmail(!false) }}
              />
              {validEmail === false ? <div className="text-red-600">This field cannot be empty</div> : <div />}
            </label>
            <ButtonPrimary type="submit">Submit</ButtonPrimary>
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Remember your password? {` `}
            <Link to="/login">Log-in here</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ForgotPass;
