import { FC, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { State, actionCreators } from "state";
import { bindActionCreators } from "redux";


export interface PayPageProps {
  className?: string;
}

const PayPage: FC<PayPageProps> = ({ className = "" }) => {


  const dispatch = useDispatch();
  const { cancelFlight } = bindActionCreators(actionCreators, dispatch);
  const flightDataOneState = useSelector((state: State) => state.storeFlightDataOneReducer)
  const flightDataTwoState = useSelector((state: State) => state.storeFlightDataTwoReducer)


  const customerDataState = useSelector((state: State) => state.storeCustomerDataReducer)

  const basicState = useSelector((state: State) => state.getBasicDetailsReducer)

  // {basicState.basicDetailsObject?.returnType === "oneWay" ?


  const renderContent = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">
          Thank you for your booking, {customerDataState.customerData.at(-1)?.firstName[0].name_0}!

        </h2>

        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* ------------------------ */}
        <div className="space-y-6">
          {/* GET CITY IN OBJECT */}
          <h3 className="text-2xl font-semibold">Pack your bags, you're off to {flightDataOneState.flightDataOneObject?.destination_city}!</h3>
          <div className="flex flex-col sm:flex-row sm:items-center">

            {basicState.basicDetailsObject?.returnType === "oneWay" ? <div className="flex-shrink-0 w-full sm:w-40"><div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
              <img src={flightDataOneState.flightDataOneObject?.airline_logo} />
            </div></div> : <div />}
            {/* </div> */}

            {basicState.basicDetailsObject?.returnType === "roundTrip" && flightDataOneState.flightDataOneObject?.layover === false && flightDataTwoState.flightDataTwoObject?.layover === false ?
              <div style={{ display: 'flex' }}>
                <span style={{ flex: '1' }}>
                  <img src={flightDataOneState.flightDataOneObject?.airline_logo} />
                </span>
                <span style={{ flex: '1' }}>
                  <img src={flightDataTwoState.flightDataTwoObject?.airline_logo} />
                </span>
              </div>
              : basicState.basicDetailsObject?.returnType === "roundTrip" && flightDataOneState.flightDataOneObject?.layover === true && flightDataTwoState.flightDataTwoObject?.layover === false ?
                <div style={{ display: 'flex' }}>
                  <span style={{ flex: '1' }}>
                    <img src={flightDataOneState.flightDataOneObject?.airline_logo} />
                  </span>
                  <span style={{ flex: '1' }}>
                    <img src={flightDataTwoState.flightDataTwoObject?.airline_logo} />
                  </span>
                </div>
                : basicState.basicDetailsObject?.returnType === "roundTrip" && flightDataOneState.flightDataOneObject?.layover === false && flightDataTwoState.flightDataTwoObject?.layover === true ?
                  <div style={{ display: 'flex' }}>
                    <span style={{ flex: '1' }}>
                      <img src={flightDataOneState.flightDataOneObject?.airline_logo} />
                    </span>
                    <span style={{ flex: '1' }}>
                      <img src={flightDataTwoState.flightDataTwoObject?.airline_logo} />
                    </span>
                  </div>
                  : basicState.basicDetailsObject?.returnType === "roundTrip" && flightDataOneState.flightDataOneObject?.layover === true && flightDataTwoState.flightDataTwoObject?.layover === true ?
                    <div style={{ display: 'flex' }}>
                      <span style={{ flex: '1' }}>
                        <img src={flightDataOneState.flightDataOneObject?.airline_logo} />
                      </span>
                      <span style={{ flex: '1' }}>
                        <img src={flightDataTwoState.flightDataTwoObject?.airline_logo} />
                      </span>
                    </div>
                    :
                    <div />}







            <div className="pt-5  sm:pb-5 sm:px-5 space-y-3">
              {basicState.basicDetailsObject?.returnType === "oneWay" && flightDataOneState.flightDataOneObject?.layover === false ?
                <div>
                  <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                    Flight from {flightDataOneState.flightDataOneObject?.origin_airport_name} to {flightDataOneState.flightDataOneObject?.destination_airport_name}
                  </span>
                  <span className="text-base sm:text-lg font-medium mt-1 block">
                    {flightDataOneState.flightDataOneObject?.origin_airport_code} · {flightDataOneState.flightDataOneObject?.destination_airport_code}
                  </span>
                </div>
                : basicState.basicDetailsObject?.returnType === "oneWay" && flightDataOneState.flightDataOneObject?.layover === true ?
                  <div>
                    <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                      Flight from {flightDataOneState.flightDataOneObject?.origin_airport_name} to {flightDataOneState.flightDataOneObject?.layover_origin} to {flightDataOneState.flightDataOneObject?.destination_airport_name}
                    </span>
                    <span className="text-base sm:text-lg font-medium mt-1 block">
                      {flightDataOneState.flightDataOneObject?.origin_airport_code} · {flightDataOneState.flightDataOneObject?.layover_airport} · {flightDataOneState.flightDataOneObject?.destination_airport_code}
                    </span>
                  </div>
                  : <div />
              }


              {/* LAYOVER TWO WAY */}

              {basicState.basicDetailsObject?.returnType === "roundTrip" && flightDataOneState.flightDataOneObject?.layover === false && flightDataTwoState.flightDataTwoObject?.layover === false ?
                <div>
                  <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                    Outbound flight from {flightDataOneState.flightDataOneObject?.origin_airport_name} to {flightDataOneState.flightDataOneObject?.destination_airport_name}
                  </span>
                  <span className="text-base sm:text-lg font-medium mt-1 block">
                    {flightDataOneState.flightDataOneObject?.origin_airport_code} · {flightDataOneState.flightDataOneObject?.destination_airport_code}
                  </span>
                  <div className="w-10 border-b border-neutral-200  dark:border-neutral-700 mt-2 mb-2"></div>
                  <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                    Inbound flight from {flightDataTwoState.flightDataTwoObject?.origin_airport_name} to {flightDataTwoState.flightDataTwoObject?.destination_airport_name}
                  </span>
                  <span className="text-base sm:text-lg font-medium mt-1 block">
                    {flightDataTwoState.flightDataTwoObject?.origin_airport_code} · {flightDataTwoState.flightDataTwoObject?.destination_airport_code}
                  </span>
                </div>
                : basicState.basicDetailsObject?.returnType === "roundTrip" && flightDataOneState.flightDataOneObject?.layover === true && flightDataTwoState.flightDataTwoObject?.layover === false ?
                  <div>
                    <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-2">
                      Outbound flight from {flightDataOneState.flightDataOneObject?.origin_airport_name} to {flightDataOneState.flightDataOneObject?.layover_origin} to {flightDataOneState.flightDataOneObject?.destination_airport_name}
                    </span>
                    <span className="text-base sm:text-lg font-medium mt-1 block">
                      {flightDataOneState.flightDataOneObject?.origin_airport_code} · {flightDataOneState.flightDataOneObject?.layover_airport} · {flightDataOneState.flightDataOneObject?.destination_airport_code}
                    </span>
                    <div className="w-10 border-b border-neutral-200  dark:border-neutral-700 mt-2 mb-2"></div>
                    <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                      Inbound flight from {flightDataTwoState.flightDataTwoObject?.origin_airport_name} to {flightDataTwoState.flightDataTwoObject?.destination_airport_name}
                    </span>
                    <span className="text-base sm:text-lg font-medium mt-1 block">
                      {flightDataTwoState.flightDataTwoObject?.origin_airport_code} · {flightDataTwoState.flightDataTwoObject?.destination_airport_code}
                    </span>
                  </div>
                  : basicState.basicDetailsObject?.returnType === "roundTrip" && flightDataOneState.flightDataOneObject?.layover === false && flightDataTwoState.flightDataTwoObject?.layover === true ?
                    <div>
                      <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                        Outbound flight from {flightDataOneState.flightDataOneObject?.origin_airport_name} to {flightDataOneState.flightDataOneObject?.destination_airport_name}
                      </span>
                      <span className="text-base sm:text-lg font-medium mt-1 block">
                        {flightDataOneState.flightDataOneObject?.origin_airport_code} · {flightDataOneState.flightDataOneObject?.destination_airport_code}
                      </span>
                      <div className="w-10 border-b border-neutral-200  dark:border-neutral-700 mt-2 mb-2"></div>
                      <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                        Inbound flight from {flightDataTwoState.flightDataTwoObject?.origin_airport_name} to {flightDataTwoState.flightDataTwoObject?.layover_origin} to {flightDataTwoState.flightDataTwoObject?.destination_airport_name}
                      </span>
                      <span className="text-base sm:text-lg font-medium mt-1 block">
                        {flightDataTwoState.flightDataTwoObject?.origin_airport_code} · {flightDataTwoState.flightDataTwoObject?.layover_airport} · {flightDataTwoState.flightDataTwoObject?.destination_airport_code}
                      </span>
                    </div>
                    : basicState.basicDetailsObject?.returnType === "roundTrip" && flightDataOneState.flightDataOneObject?.layover === true && flightDataTwoState.flightDataTwoObject?.layover === true ?
                      <div>
                        <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-2">
                          Outbound flight from {flightDataOneState.flightDataOneObject?.origin_airport_name} to {flightDataOneState.flightDataOneObject?.layover_origin} to {flightDataOneState.flightDataOneObject?.destination_airport_name}
                        </span>
                        <span className="text-base sm:text-lg font-medium mt-1 block">
                          {flightDataOneState.flightDataOneObject?.origin_airport_code} · {flightDataOneState.flightDataOneObject?.layover_airport} · {flightDataOneState.flightDataOneObject?.destination_airport_code}
                        </span>
                        <div className="w-10 border-b border-neutral-200  dark:border-neutral-700 mt-2 mb-2"></div>
                        <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                          Inbound flight from {flightDataTwoState.flightDataTwoObject?.origin_airport_name} to {flightDataTwoState.flightDataTwoObject?.layover_origin} to {flightDataTwoState.flightDataTwoObject?.destination_airport_name}
                        </span>
                        <span className="text-base sm:text-lg font-medium mt-1 block">
                          {flightDataTwoState.flightDataTwoObject?.origin_airport_code} · {flightDataTwoState.flightDataTwoObject?.layover_airport} · {flightDataTwoState.flightDataTwoObject?.destination_airport_code}
                        </span>
                      </div>
                      :
                      <div />
              }










              {/* <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
              <StartRating /> */}
            </div>
          </div>

        </div>
        <div className="border-b border-neutral-200 dark:border-neutral-700 mt-8"></div>


        {/* ------------------------ */}
        <div className="space-y-6">
          <h3 className="text-2xl font-semibold">Booking Summary</h3>
          <div className="flex flex-col space-y-4">
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Confirmation Number</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {customerDataState.customerData.at(-1)?.confirmationNumber}
              </span>
            </div>
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Number of Passengers</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {flightDataOneState.flightDataOneObject?.number_of_passengers}
              </span>
            </div>
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Flight Date</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {flightDataOneState.flightDataOneObject?.departing_at_date}
              </span>
            </div>
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Total Cost incl. taxes and fees</span>
              {basicState.basicDetailsObject?.returnType === "oneWay" ?
                <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                  {flightDataOneState.flightDataOneObject?.flight_total_price} {flightDataOneState.flightDataOneObject?.flight_currency}
                </span> : <span />}


              {basicState.basicDetailsObject?.returnType === "roundTrip" ?
                <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                  {Number(flightDataOneState.flightDataOneObject?.flight_total_price) + Number(flightDataTwoState.flightDataTwoObject?.flight_total_price)} {flightDataOneState.flightDataOneObject?.flight_currency}
                </span> : <span />}


            </div>
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Payment Method</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                Credit Card
              </span>
            </div>

          </div>
        </div>
        <div className="border-b border-neutral-200 dark:border-neutral-700 mt-8"></div>

        <div className="space-y-6">
          <h3 className="text-2xl font-semibold">Customer Details</h3>
          <div className="flex flex-col space-y-4">
            {customerDataState.customerData.at(-1)?.firstName.map((item: object, index: number) => {
              return (
                <div className="flex text-neutral-6000 dark:text-neutral-300" key={index}>
                  <span className="flex-1">Passenger {index + 1} Name</span>
                  <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                    {Object.values(customerDataState.customerData.at(-1)?.firstName[index])} {Object.values(customerDataState.customerData.at(-1)?.lastName[index])}
                  </span>
                </div>
              )
            })}

            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">E-Mail Address</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {customerDataState.customerData.at(-1)?.email}
              </span>
            </div>
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Phone Number</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {customerDataState.customerData.at(-1)?.phone}
              </span>
            </div>
          </div>
        </div>

        <div>
          {/* <ButtonPrimary href="/">Explore more stays</ButtonPrimary> */}
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-PayPage ${className}`} data-nc-id="PayPage">
      <main className="container mt-11 mb-24 lg:mb-32 ">
        <div className="max-w-4xl mx-auto">{renderContent()}</div>
      </main>
    </div>
  );
};

export default PayPage;
