import { ActionType } from 'state/action-types'
import { Action, PossibleFlightDataOneObject } from '../actions/index'

const initialState = {
    load: <boolean>(false)
}

const setLoadReducer = (state = initialState, action: Action) => {
    if (action.type == ActionType.SET_LOAD) {
        let newLoad = action.payload
        return {
            ...state,
            load: newLoad
        }
    } else {
        return state;
    }
}

export default setLoadReducer;