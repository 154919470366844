import FlightCard from "components/FlightCard/FlightCard";
import FlightCardReturn from "components/FlightCard/FlightCardReturn";
import Heading2 from "components/Heading/Heading2";
import { FC } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators, State } from "state";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { PossibleOfferObject } from "state/actions/index";
import StaticFlightCard from "components/FlightCard/StaticFlightCard";
import { MoonLoader } from "react-spinners";

export interface SectionGridFilterCardProps {
  className?: string;
  flightDataArray?: PossibleOfferObject;
}

// const DEMO_DATA: FlightCardProps["data"][] = [
//   {
//     id: "1",
//     price: "$4,100",
//     airlines: {
//       logo: "https://www.gstatic.com/flights/airline_logos/70px/KE.png",
//       name: "Korean Air",
//     },
//   },
//   {
//     id: "2",
//     price: "$3,380",
//     airlines: {
//       logo: "https://www.gstatic.com/flights/airline_logos/70px/SQ.png",
//       name: "Singapore Airlines",
//     },
//   },
//   {
//     id: "3",
//     price: "$2,380",
//     airlines: {
//       logo: "https://www.gstatic.com/flights/airline_logos/70px/multi.png",
//       name: "Philippine Airlines",
//     },
//   },
//   {
//     id: "1",
//     price: "$4,100",
//     airlines: {
//       logo: "https://www.gstatic.com/flights/airline_logos/70px/KE.png",
//       name: "Korean Air",
//     },
//   },
//   {
//     id: "2",
//     price: "$3,380",
//     airlines: {
//       logo: "https://www.gstatic.com/flights/airline_logos/70px/SQ.png",
//       name: "Singapore Airlines",
//     },
//   },
//   {
//     id: "1",
//     price: "$4,100",
//     airlines: {
//       logo: "https://www.gstatic.com/flights/airline_logos/70px/KE.png",
//       name: "Korean Air",
//     },
//   },
//   {
//     id: "2",
//     price: "$3,380",
//     airlines: {
//       logo: "https://www.gstatic.com/flights/airline_logos/70px/SQ.png",
//       name: "Singapore Airlines",
//     },
//   },
// ];

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
  flightDataArray,
}) => {
  const dispatch = useDispatch();

  const basicFlightState = useSelector((state: State) => state.getBasicDetailsReducer)
  const { setLoad } = bindActionCreators(actionCreators, dispatch);
  const loadState = useSelector((state: State) => state.setLoadReducer)



  const history = useHistory();

  var numberOfPassengers = 1
  var returnType: string | undefined = ""
  var origin = ""
  var destination = ""

  var numberOfFlights = flightDataArray?.offer.length
  if (flightDataArray?.offer[0] !== undefined) {
    numberOfPassengers = flightDataArray?.offer[0].passengers.length ?? 1
    returnType = basicFlightState.basicDetailsObject?.returnType
    origin = flightDataArray?.offer[0].slices[0].origin.city_name
    destination = flightDataArray?.offer[0].slices[0].destination.city_name
  } else {
    alert("No flights found!")
    history.push("/")
  }

  { loadState.load === true ? document.body.style.opacity = "0.4" : document.body.style.opacity = "1" }
  { loadState.load === true ? document.body.style.pointerEvents = "none" : document.body.style.pointerEvents = "auto" }

  return (




    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      <div style={{ textAlign: 'center' }}>
        <Heading2
          heading={<span>{origin} - {destination}</span>}
          subHeading={
            <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
              {numberOfFlights} flights
              <span className="mx-2">·</span>
              {returnType == "roundTrip" ? <span>Round Trip</span> : <span>One Way</span>}
              <span className="mx-2">·</span>
              {numberOfPassengers > 1 ? <span>{numberOfPassengers} passengers</span> : <span>{numberOfPassengers} passenger</span>}
            </span>
          }
        />
      </div>
      {/* <div className="mb-8 lg:mb-11">
        <TabFilters />
      </div> */}
      <div className="lg:p-10 lg:bg-neutral-50 lg:dark:bg-black/20 grid grid-cols-1 gap-6  rounded-3xl">
        {returnType === "roundTrip" && origin === "London" && destination === "Melbourne" && numberOfPassengers == 1 && flightDataArray?.offer[0].slices[0].segments[0].departing_at.includes("2024-11-04") ? <StaticFlightCard /> : <div />}
        {returnType == "oneWay" ? flightDataArray?.offer.map((item, index) => (
          <FlightCard key={index} flightData={item} returnType={returnType} />
        )) : flightDataArray?.offer.map((item, index) => (
          <FlightCardReturn key={index} flightData={item} returnType={returnType} />
        ))}


        <div className="flex mt-12 justify-center items-center">
          {/* {flightDataArray?.meta.after ? <ButtonPrimary onClick={handleSubmit}>Show more</ButtonPrimary> : <div />} */}
        </div>
      </div>
    </div>
  );
};

export default SectionGridFilterCard;
