export enum ActionType {
    GET_OFFER_REQUEST = "GET_OFFER_REQUEST",
    GET_OFFERS = "GET_OFFERS",
    GET_RETURN_OFFERS = "GET_RETURN_OFFERS",
    STORE_FLIGHT_DATA_ONE = "STORE_FLIGHT_DATA_ONE",
    STORE_FLIGHT_DATA_TWO = "STORE_FLIGHT_DATA_TWO",
    STORE_CUSTOMER_DATA = "STORE_CUSTOMER_DATA",
    GET_BASIC_DETAILS = "GET_BASIC_DETAILS",
    STORE_LOGIN = "STORE_LOGIN",
    CANCEL_FLIGHT = "CANCEL_FLIGHT",
    CHANGE_CURRENCY = "CHANGE_CURRENCY",
    STATIC_FLIGHT_RETURN_ONE = "STATIC_FLIGHT_RETURN_ONE",
    STATIC_FLIGHT_RETURN_TWO = "STATIC_FLIGHT_RETURN_TWO",
    SET_LOAD = "SET_LOAD"
}