import React from "react";
import { BrowserRouter, HashRouter, Switch, Route } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import CheckOutPage from "containers/CheckOutPage/CheckOutPage";
import PayPage from "containers/PayPage/PayPage";
import AccountPage from "containers/AccountPage/AccountPage";
import AccountPass from "containers/AccountPage/AccountPass";
import AccountBilling from "containers/AccountPage/AccountBilling";
import PageContact from "containers/PageContact/PageContact";
import PageAbout from "containers/PageAbout/PageAbout";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageLogin from "containers/PageLogin/PageLogin";
import BlogPage from "containers/BlogPage/BlogPage";
import BlogSingleSingapore from "containers/BlogPage/BlogSingleSingapore";
import PageHome2 from "containers/PageHome/PageHome2";
import SiteHeader from "containers/SiteHeader";
import ListingFlightsPage from "containers/ListingFlightsPage/ListingFlightsPage";
import ReturnListingFlightsPage from "containers/ListingFlightsPage/ReturnListingFlightsPage";
import ReturnListingFlightsPageAgain from "containers/ListingFlightsPage/ReturnListingFlightsPageAgain";
import TermsPage from "containers/LegalPage/TermsPage";
import PolicyPage from "containers/LegalPage/PolicyPage";
import ForgotPass from "containers/ForgotPass/ForgotPass";
import BlogSingleNewYork from "containers/BlogPage/BlogSingleNewYork";
import BlogSingleParis from "containers/BlogPage/BlogSingleParis";
import BlogSingleLondon from "containers/BlogPage/BlogSingleLondon";
import BlogSingleTokyo from "containers/BlogPage/BlogSingleTokyo";
import BlogSingleMaldives from "containers/BlogPage/BlogSingleMaldives";


export const pages: Page[] = [
  { path: "/", exact: true, component: PageHome },
  { path: "/#", exact: true, component: PageHome },
  // { path: "/home-1-header-2", exact: true, component: PageHome },
  // { path: "/home-2", component: PageHome2 },
  //
  { path: "/listing-flights", component: ListingFlightsPage },
  { path: "/return-flights", component: ReturnListingFlightsPage },
  { path: "/choose-return-flights", component: ReturnListingFlightsPageAgain },

  //
  { path: "/checkout", component: CheckOutPage },
  { path: "/pay-done", component: PayPage },
  //
  { path: "/account", component: AccountPage },
  // { path: "/account-password", component: AccountPass },
  { path: "/my-flights", component: AccountBilling },
  //
  // { path: "/blog", component: BlogPage },
  { path: "/explore-singapore", component: BlogSingleSingapore },
  { path: "/explore-newyork", component: BlogSingleNewYork },
  { path: "/explore-paris", component: BlogSingleParis },
  { path: "/explore-london", component: BlogSingleLondon },
  { path: "/explore-tokyo", component: BlogSingleTokyo },
  { path: "/explore-maldives", component: BlogSingleMaldives },
  //
  { path: "/contact", component: PageContact },
  { path: "/about", component: PageAbout },
  { path: "/signup", component: PageSignUp },
  { path: "/login", component: PageLogin },
  { path: "/forgot-pass", component: ForgotPass },


  { path: "/terms", component: TermsPage },
  { path: "/privacy", component: PolicyPage },
  { path: "/error", component: Page404 },


  //
];

const Routes = () => {
  return (
    <BrowserRouter basename="/">
      <ScrollToTop />
      <SiteHeader />

      <Switch>
        {pages.map(({ component, path, exact }) => {
          return (
            <Route
              key={path}
              component={component}
              exact={!!exact}
              path={path}
            />
          );
        })}
        <Route component={Page404} />
      </Switch>
      <Footer />
    </BrowserRouter>
  );
};

export default Routes;
