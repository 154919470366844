import { ActionType } from 'state/action-types'
import { Action, PossibleBasicDetailsObject } from '../actions/index'

const initialState = {
    basicDetailsObject: <PossibleBasicDetailsObject>(null)
}

const getBasicDetailsReducer = (state = initialState, action: Action) => {
    if (action.type == ActionType.GET_BASIC_DETAILS) {
        let newBasicDetailsObject = action.payload
        return {
            ...state,
            basicDetailsObject: newBasicDetailsObject
        }
    } else {
        return state;
    }
}

export default getBasicDetailsReducer;