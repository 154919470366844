import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHeroArchivePageReturn from "components/SectionHeroArchivePage/SectionHeroArchivePageReturn";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import { FC, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { actionCreators, State } from "state";
import ReturnSectionGridFilterCard from "./ReturnSectionGridFilterCard";

export interface ListingFlightsPageProps {
  className?: string;
}


const ReturnListingFlightsPage: FC<ListingFlightsPageProps> = ({
  className = "",
}) => {

  const history = useHistory();
  const dispatch = useDispatch();

  const { getOffers } = bindActionCreators(actionCreators, dispatch);
  const state = useSelector((state: State) => state.getOfferObject)



  const flightsArray = state.offerObject;


  return (
    <div
      className={`nc-ListingFlightsPage relative overflow-hidden ${className}`}
      data-nc-id="ListingFlightsPage"
    >

      <Helmet>
        <title>Flights | Travel 7 World</title>
      </Helmet>
      <BgGlassmorphism />

      <div className="container relative">
        {/* SECTION HERO */}
        <SectionHeroArchivePageReturn
          currentPage="Flights"
          currentTab="Flights"
          listingType={
            <>
              <i className="text-2xl las la-plane-departure"></i>
              <span className="ml-2.5">{flightsArray?.offer.length} flights</span>
            </>
          }
          className="pt-10 pb-24 lg:pb-32 lg:pt-16 "
        />

        {/* SECTION */}
        <ReturnSectionGridFilterCard className="pb-24 lg:pb-32" flightDataArray={flightsArray} />

        {/* SECTION 1 */}
        <SectionSliderNewCategories
          heading="Explore top destination ✈"
          subHeading="Explore thousands of destinations around the world"
          categoryCardType="card4"
          itemPerRow={4}
          uniqueClassName="ListingFlightsPage-section1"
        />

        {/* SECTION */}
        {/* <SectionSubscribe2 className="py-24 lg:py-32" /> */}
        <br></br>
        <br></br>
      </div>
    </div>
  );
};

export default ReturnListingFlightsPage;
