import { ActionType } from 'state/action-types'
import { Action, PossibleLoginData } from '../actions/index'

const initialState = {
    loginData: <PossibleLoginData>(null)
}

const storeLoginReducer = (state = initialState, action: Action) => {
    if (action.type == ActionType.STORE_LOGIN) {
        let newLoginData = action.payload
        return {
            ...state,
            loginData: newLoginData
        }
    } else {
        return state;
    }
}

export default storeLoginReducer;