import { ActionType } from 'state/action-types'
import { Action, PossibleCustomerDataArray, PossibleCustomerDataObject } from '../actions/index'

const initialState = {
    customerData: <PossibleCustomerDataObject[]>(
        [
            {
                firstName: [{ name_0: "" }],
                lastName: [{ name_0: "" }],
                cardNumber: "",
                cardholderName: "",
                expiryDate: "",
                cvv: "",
                streetAddress: "",
                country: "",
                zipCode: "",
                city: "",
                email: "",
                phone: "",
                confirmationNumber: 0,
                from: "",
                to: "",
                airlineLogo: ""
            }
        ]
    )
}

const storeCustomerDataReducer = (state = initialState, action: Action) => {
    if (action.type == ActionType.STORE_CUSTOMER_DATA && state.customerData) {
        let newCustomerData = action.payload
        return {
            ...state,
            customerData: [...state.customerData, newCustomerData]
        }
    } else if (action.type == ActionType.STORE_CUSTOMER_DATA && state.customerData === null) {
        let newCustomerData = action.payload
        state = initialState
        return {
            ...state,
            customerData: [...state.customerData, newCustomerData]
        }
    } else if (action.type == ActionType.CANCEL_FLIGHT) {
        return {
            ...state,
            customerData: [
                ...state.customerData.slice(0, action.payload),
                ...state.customerData.slice(action.payload + 1)
            ],
        }
    }
    else {
        return state;
    }
}

export default storeCustomerDataReducer;