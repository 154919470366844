import { ActionType } from 'state/action-types'
import { Action, PossibleBasicDetailsObject } from '../actions/index'

const initialState = {
    currency: <string>("USD")
}

const changeCurrencyReducer = (state = initialState, action: Action) => {
    if (action.type == ActionType.CHANGE_CURRENCY) {
        let newCurrency = action.payload
        return {
            ...state,
            currency: newCurrency
        }
    } else {
        return state;
    }
}

export default changeCurrencyReducer;