import React, { FC, useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import Logo from "shared/Logo/Logo";
import Navigation from "shared/Navigation/Navigation";
import SearchDropdown from "./SearchDropdown";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import MenuBar from "shared/MenuBar/MenuBar";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import { useDispatch, useSelector } from "react-redux"
import { bindActionCreators } from "redux";
import { actionCreators, State } from "state";
import jwt from 'jsonwebtoken';
import { storeLogin } from "state/action-creators";

export interface MainNav1Props {
  isTop: boolean;
}

const MainNav1: FC<MainNav1Props> = ({ isTop }) => {

  const [auth, setAuth] = useState(false)

  const history = useHistory();

  const dispatch = useDispatch();

  const { storeLogin } = bindActionCreators(actionCreators, dispatch);
  const state = useSelector((state: State) => state.storeLoginReducer)




  // useEffect(() => {
  //   // const token = localStorage.getItem('token')
  //   if (state.loginData?.loggedIn === true) {

  //     setAuth(true)
  //   }
  // }, [])

  const handleLogOut = () => {
    storeLogin({ loggedIn: false })
    // localStorage.removeItem('token')
  }

  return (
    <div
      className={`nc-MainNav1 relative z-10 ${isTop ? "onTop " : "notOnTop backdrop-filter"
        }`}
    >
      <div className="container py-5 relative flex justify-between items-center space-x-4 xl:space-x-8">
        <div className="flex justify-start flex-grow items-center space-x-4 sm:space-x-10 2xl:space-x-14">
          <Logo />
          <Navigation />
        </div>
        <div className="flex-shrink-0 flex items-center justify-end text-neutral-700 dark:text-neutral-100 space-x-1">
          <div className="hidden items-center xl:flex space-x-1">
            <SwitchDarkMode />
            {/* <SearchDropdown /> */}
            <div className="px-1" />
            {state.loginData?.loggedIn == false || state.loginData?.loggedIn == null ?
              <div><ButtonPrimary className="mr-2 ml-2" href="/signup">Register</ButtonPrimary>
                <ButtonPrimary href="/login">Log in</ButtonPrimary> </div>
              : <div><ButtonPrimary className="mr-2 ml-2" href="/account">My Account</ButtonPrimary> <ButtonPrimary onClick={handleLogOut}>Log Out</ButtonPrimary> </div>}

          </div>
          <div className="flex items-center xl:hidden">
            <SwitchDarkMode />
            <div className="px-1" />
            <MenuBar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNav1;
