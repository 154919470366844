const indiFareRules = () => {
    return (
        <div>
            <p>RULE - 023/EUR3 UNLESS OTHERWISE SPECIFIED AT FARES APPLICATION CLASS OF SERVICE THESE FARES APPLY FOR ECONOMY CLASS SERVICE. TYPES OF TRANSPORTATION THIS RULE GOVERNS ONE-WAY AND ROUND-TRIP FARES. FARES GOVERNED BY THIS RULE CAN BE USED TO CREATE ONE-WAY/ROUND-TRIP/OPEN-JAW/SINGLE OPEN-JAW JOURNEYS. FARES ONLY APPLY IF PURCHASED BEFORE DEPARTURE. CAPACITY LIMITATIONS THE CARRIER SHALL LIMIT THE NUMBER OF PASSENGERS CARRIED ON ANY ONE FLIGHT AT FARES GOVERNED BY THIS RULE AND SUCH FARES WILL NOT NECESSARILY BE AVAILABLE ON ALL FLIGHTS. THE NUMBER OF SEATS WHICH THE CARRIER SHALL MAKE AVAILABLE ON A GIVEN FLIGHT WILL BE DETERMINED BY THE CARRIERS BEST JUDGMENT OTHER CONDITIONS FULL AND SEQUENTIAL USE OF FLIGHT COUPONS- THE TICKET/ OR ELECTRONIC TICKET / IS NOT VALID IF THE FIRST COUPON HAS NOT BEEN USED AND WILL NOT BE HONORED IF ALL THE COUPONS ARE NOT USED IN THE SEQUENCE PROVIDED IN THE TICKET / OR ELECTRONIC TICKET / . THE FARE THAT APPLIES ON THE DATE OF PURCHASE IS ONLY VALID FOR THE ENTIRE ITINERARY AND THE SPECIFIC TRAVEL DATES MENTIONED ON THE TICKET. ANY MODIFICATION MAY REQUIRE THE PAYMENT OF AN ADDITIONAL AMOUNT.<br />
                UNLESS OTHERWISE SPECIFIED THE FARE COMPONENT MUST BE ON ONE OR MORE OF THE FOLLOWING ANY AT FLIGHT OPERATED BY AT.<br />
                UNLESS OTHERWISE SPECIFIED NOTE - RULE ADAT IN IPRG100 APPLIES UNLESS OTHERWISE SPECIFIED RESERVATIONS ARE REQUIRED FOR ALL SECTORS.<br />
                UNLESS OTHERWISE SPECIFIED NOTE - RULE MNAT IN IPRG100 APPLIES<br />
                UNLESS OTHERWISE SPECIFIED NOTE - RULE MXAT IN IPRG100 APPLIES UNLESS OTHERWISE SPECIFIED TRAVEL FROM LAST STOPOVER MUST COMMENCE NO LATER THAN 12 MONTHS AFTER DEPARTURE FROM FARE ORIGIN.<br />
                UNLESS OTHERWISE SPECIFIED NOTE - RULE SOAT IN IPRG100 APPLIES FOR M- TYPE FARES NOTE - GENERAL RULE DOES NOT APPLY 2 FREE STOPOVERS PERMITTED ON THE PRICING UNIT - 1 IN EACH DIRECTION. A STOPOVER MAY NOT EXCEED 21 DAYS.<br />
                UNLESS OTHERWISE SPECIFIED NOTE - RULE TFAT IN IPRG100 APPLIES UNLESS OTHERWISE SPECIFIED UNLIMITED TRANSFERS PERMITTED ON THE PRICING UNIT FARE BREAK SURFACE SECTORS NOT PERMITTED AND EMBEDDED SURFACE SECTORS PERMITTED ON THE FARE COMPONENT.<br />
                UNLESS OTHERWISE SPECIFIED APPLICABLE ADD-ON CONSTRUCTION IS ADDRESSED IN MISCELLANEOUS PROVISIONS - CATEGORY 23. END-ON-END END-ON-END COMBINATIONS PERMITTED WITH DOMESTIC FARES. VALIDATE ALL FARE COMPONENTS. FARES MUST BE SHOWN SEPARATELY ON THE TICKET. TRAVEL MUST BE VIA THE POINT OF COMBINATION. SIDE TRIPS PERMITTED. OPEN JAWS/ROUND TRIPS/CIRCLE TRIPS FARES MAY BE COMBINED ON A HALF ROUND TRIP BASIS -TO FORM SINGLE OR DOUBLE OPEN JAWS/ROUND TRIPS/CIRCLE TRIPS. PROVIDED - COMBINATIONS ARE WITH ANY FARE FOR CARRIER AT IN ANY RULE AND TARIFF.<br />
                BETWEEN LON AND LOS FOR M- TYPE FARES THE PROVISIONS BELOW APPLY ONLY AS FOLLOWS - SALE IS RESTRICTED TO SPECIFIC AGENTS. ORIGINATING LON - A SURCHARGE OF 25 PERCENT OF THE FARE PER TICKET WILL BE ADDED TO THE APPLICABLE FARE FOR TRAVEL. AND - A SURCHARGE OF 5 PERCENT OF THE FARE PER TICKET WILL BE ADDED TO THE APPLICABLE FARE FOR TRAVEL. A SURCHARGE OF 5 PERCENT OF THE FARE PER TICKET WILL BE ADDED TO THE APPLICABLE FARE FOR TRAVEL.<br />
                UNLESS OTHERWISE SPECIFIED NOTE - RULE SRAT IN IPRG100 APPLIES FROM/TO LOS IF THE FARE COMPONENT IS ON ONE OR MORE OF THE FOLLOWING ANY AT FLIGHT OPERATED BY AT TICKETS MUST BE ISSUED ON AT AND MAY NOT BE SOLD IN EGYPT/RUSSIA EXTENSION OF TICKET VALIDITY IS NOT PERMITTED. OR - TICKETS MUST BE ISSUED ON AT OR HR AND MAY NOT BE SOLD IN EGYPT/RUSSIA OR - TICKETS MUST BE ISSUED ON AT OR W2 AND MAY NOT BE SOLD IN EGYPT/RUSSIA OR - TICKETS MUST BE ISSUED ON AT OR GP AND MAY NOT BE SOLD IN EGYPT/RUSSIA OR - TICKETS MUST BE ISSUED ON AT OR HF AND MAY NOT BE SOLD IN EGYPT/RUSSIA IF THE FARE COMPONENT IS ON ONE OR MORE OF THE FOLLOWING AT FLIGHTS 5000 THROUGH 5999 AT FLIGHTS 9000 THROUGH 9999 TICKETS MUST BE ISSUED ON AT AND MAY NOT BE SOLD IN EGYPT/RUSSIA<br />
                UNLESS OTHERWISE SPECIFIED NOTE - RULE PEAT IN IPRG100 APPLIES FOR M- TYPE FARES CANCELLATIONS BEFORE DEPARTURE CHARGE EUR 280.00 FOR REFUND. CHILD/INFANT DISCOUNTS APPLY. CHARGE EUR 280.00 FOR NO-SHOW. CHILD/INFANT DISCOUNTS APPLY. AFTER DEPARTURE TICKET IS NON-REFUNDABLE IN CASE OF REFUND. TICKET IS NON-REFUNDABLE IN CASE OF NO-SHOW. NOTE - THE YQ REFUND FOLLOWS THE SAME CONDITIONS OF THE APPLICABLE FARE. CHANGES ANY TIME PER DIRECTION CHARGE EUR 60.00 FOR REISSUE/ REVALIDATION. PER DIRECTION CHARGE EUR 110.00 FOR NO-SHOW. NOTE - UPGRADE TO HIGHER FARE MUST BE TO THE HIGHER AVAILABLE CLASS IN THE FLIGHT DATE DISPLAY.<br />
                UNLESS OTHERWISE SPECIFIED NOTE - RULE CDAT IN IPRG100 APPLIES UNLESS OTHERWISE SPECIFIED ACCOMPANIED CHILD 2-11 - CHARGE 75 PERCENT OF THE FARE. TICKETING CODE - BASE FARE CODE PLUS CH. MUST BE ACCOMPANIED ON ALL FLIGHTS IN SAME COMPARTMENT BY ADULT 18 OR OLDER OR - INFANT UNDER 2 WITH A SEAT - CHARGE 75 PERCENT OF THE FARE. TICKETING CODE - BASE FARE CODE PLUS INS. MUST BE ACCOMPANIED ON ALL FLIGHTS IN SAME COMPARTMENT BY ADULT 18 OR OLDER OR - 1ST INFANT UNDER 2 WITHOUT A SEAT - CHARGE 10 PERCENT OF THE FARE. TICKETING CODE - BASE FARE CODE PLUS INF. MUST BE ACCOMPANIED ON ALL FLIGHTS IN SAME COMPARTMENT BY ADULT 18 OR OLDER OR - UNACCOMPANIED CHILD 4-14 - CHARGE 100 PERCENT OF THE FARE PLUS EUR 50.00. TICKETING CODE - BASE FARE CODE PLUS UM.<br />
                NONE UNLESS OTHERWISE SPECIFIED<br />
                UNLESS OTHERWISE SPECIFIED NOTE - RULE MPAT IN IPRG100 APPLIES UNLESS OTHERWISE SPECIFIED THIS FARE MUST NOT BE USED AS THE HIGH OR THE LOW FARE WHEN CALCULATING A DIFFERENTIAL. THIS FARE MUST NOT BE USED AS THE THROUGH FARE WHEN PRICING A FARE COMPONENT WITH A DIFFERENTIAL.<br />
                DO A CATEGORY 31 SPECIFIC TEXT ENTRY TO VIEW CONTENTS ALSO REFERENCE 16 PENALTIES - FOR ADDITIONAL CHANGE INFORMATION<br />
                UNLESS OTHERWISE SPECIFIED NOTE - RULE VRAT IN IPRG100 APPLIES FOR M- TYPE FARES REFUND MUST BE REQUESTED BEFORE DEPARTURE OF JOURNEY. REFUND REQUEST REQUIRED BEFORE ORIGINALLY SCHEDULED FLIGHT OF FIRST UNUSED TICKET COUPON. CHARGE EUR 280.00 PER PRICING UNIT. IF ALL PENALTIES IN PRICING UNIT ARE PER PRICING UNIT COLLECT HIGHEST. IF MIX OF PER FARE COMPONENT AND PER PRICING UNIT CALCULATE EACH AS SPECIFIED AND COLLECT HIGHEST. FORM OF REFUND - ORIGINAL FORM OF PAYMENT. ONLY VALIDATING CARRIER MAY REFUND TICKET. REPRICE USING EQUAL OR HIGHER RBD. OR - REFUND MUST BE REQUESTED BEFORE DEPARTURE OF JOURNEY. REFUND REQUEST REQUIRED ANYTIME AFTER ORIGINALLY SCHEDULED FLIGHT OF FIRST UNUSED TICKET COUPON. CHARGE EUR 280.00 PER PRICING UNIT. IF ALL PENALTIES IN PRICING UNIT ARE PER PRICING UNIT COLLECT HIGHEST. IF MIX OF PER FARE COMPONENT AND PER PRICING UNIT CALCULATE EACH AS SPECIFIED AND COLLECT HIGHEST. FORM OF REFUND - ORIGINAL FORM OF PAYMENT. ONLY VALIDATING CARRIER MAY REFUND TICKET. REPRICE USING EQUAL OR HIGHER RBD. OR - FARE IS NONREFUNDABLE AFTER DEPARTURE OF JOURNEY. FARE IS NONREFUNDABLE BEFORE ORIGINALLY SCHEDULED FLIGHT OF FIRST UNUSED TICKET COUPON. IF MIX OF PER FARE COMPONENT AND PER PRICING UNIT CALCULATE EACH AS SPECIFIED AND COLLECT HIGHEST. REPRICE USING EQUAL OR HIGHER RBD. OR - FARE IS NONREFUNDABLE AFTER DEPARTURE OF JOURNEY. FARE IS NONREFUNDABLE ANYTIME AFTER ORIGINALLY SCHEDULED FLIGHT OF FIRST UNUSED TICKET COUPON. IF MIX OF PER FARE COMPONENT AND PER PRICING UNIT CALCULATE EACH AS PER PRICING UNIT AND COLLECT HIGHEST.</p>
        </div>
    )
}

export default indiFareRules;