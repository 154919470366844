import { ActionType } from 'state/action-types'
import { Action, PossibleFlightDataOneObject } from '../actions/index'

const initialState = {
    flightDataOneObject: <PossibleFlightDataOneObject>(null)
}

const storeFlightDataOneReducer = (state = initialState, action: Action) => {
    if (action.type == ActionType.STORE_FLIGHT_DATA_ONE) {
        let newFlightDataOneObject = action.payload
        return {
            ...state,
            flightDataOneObject: newFlightDataOneObject
        }
    } else {
        return state;
    }
}

export default storeFlightDataOneReducer;