import React, { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import imagePng from "images/hero-right.png";
import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";

export interface SectionHeroProps {
  className?: string;
}

const SectionHero: FC<SectionHeroProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-SectionHero flex flex-col-reverse lg:flex-col relative ${className}`}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row lg:items-center">
        <div className="flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-8 sm:space-y-10 pb-14 lg:pb-64 xl:pr-14 lg:mr-10 xl:mr-0">
          {/* <h2 className="font-medium text-4xl md:text-5xl xl:text-7xl !leading-[114%] ">
            The world just got smaller
          </h2>
          <span className="text-base md:text-lg text-neutral-500 dark:text-neutral-400">
            Where will these incredible deals take you? Find great value deals on flights including a generous baggage allowance, and low-deposit holidays with the option to spread the payments to suit you.
          </span> */}
          {/* <ButtonPrimary>Start your search</ButtonPrimary> */}
        </div>
        <div className="flex-grow">
          {/* <img className="w-full" src={imagePng} alt="hero" /> */}
        </div>
      </div>

      <div className="z-10 mb-12 lg:mb-0 lg:-mt-80 w-full text-center">
        <HeroSearchForm />
        <div className="mt-8">
          <h2 className="font-medium text-4xl !leading-[114%] mb-8 text-center">
            Be inspired
          </h2>
          <span className="text-base md:text-lg text-neutral-500 dark:text-neutral-400 text-center">
            Where will these incredible deals take you? Find great value deals on flights including a generous baggage allowance, and low-deposit holidays with the option to spread the payments to suit you.
          </span>
        </div>
      </div>
    </div>
  );
};

export default SectionHero;
