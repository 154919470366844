import { DEMO_POSTS } from "data/posts";
import { PostDataType } from "data/types";
import React from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Comment from "shared/Comment/Comment";
import NcImage from "shared/NcImage/NcImage";
import SocialsList from "shared/SocialsList/SocialsList";
import Textarea from "shared/Textarea/Textarea";
import { Helmet } from "react-helmet";

const BlogSingleTokyo = () => {
  const renderHeader = () => {
    return (
      <header className="container rounded-xl">
        <div className="max-w-screen-md mx-auto space-y-5">
          {/* <Badge href="##" color="purple" name="Traveler" /> */}
          <h1
            className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
            title="Quiet ingenuity: 120,000 lunches and counting"
          >
            Explore Tokyo
          </h1>
          <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">

            With its two international airports, Tokyo is usually the first and last stop on every traveller’s trip to Japan, but the city deserves much more than a quick, jet-lagged pitstop. It’s a distillation of the best and worst the country has to offer – clean, safe and convenient, but also crowded, expensive and conformist (just squeeze onto a rush-hour train if you don’t believe us).

            It’s also endlessly varied, with wooden buildings sitting next to modern towers forged out of glass and steel, and haute cuisine restaurants alongside shacks that have been serving the same three noodle dishes for generations. Whether you’re interested in food, art, sports, architecture, nightlife or fashion, you’re sure to find Tokyo fascinating.
          </span>

          <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
          <div className="flex flex-col items-baseline sm:flex-row sm:justify-between">
            <div className="nc-PostMeta2 flex items-center flex-wrap text-neutral-700 text-left dark:text-neutral-200 text-sm leading-none flex-shrink-0">
              {/* <Avatar
                containerClassName="flex-shrink-0"
                sizeClass="w-8 h-8 sm:h-11 sm:w-11 "
              /> */}
              {/* <div className="ml-3">
                <div className="flex items-center">
                  <a
                    className="block font-semibold"
                    href="/ncmaz/author/the-demo-author-slug"
                  >
                    Fones Mimi
                  </a>
                </div>
                <div className="text-xs mt-[6px]">
                  <span className="text-neutral-700 dark:text-neutral-300">
                    May 20, 2021
                  </span>
                  <span className="mx-2 font-semibold">·</span>
                  <span className="text-neutral-700 dark:text-neutral-300">
                    6 min read
                  </span>
                </div>
              </div> */}
            </div>
            {/* <div className="mt-3 sm:mt-0 sm:ml-3"> */}
            {/* <SocialsList /> */}
            {/* </div> */}
          </div>
        </div>
      </header>
    );
  };

  const renderContent = () => {
    return (
      <div
        id="single-entry-content"
        className="prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
      >
        <p>
          Tokyo may well have the weirdest (and often most wonderful) museums of any city in the world. On the fifth floor of a nondescript building Nihonbashi you can visit the tiny Kite Museum, filled from floor to ceiling with traditional kites, some dating all the way back to the Edo period. Or maybe Meguro’s Parasitological Museum will pique your interest, with its grisly examples of a range of parasites. Thankfully, most of them aren’t common anymore, but if you do want to take a parasite home with you, you can buy one preserved in a keyring.        </p>
        <p>
          From white-gloved attendants politely but firmly squashing commuters into trains to stationmaster cats with their own hats, Japan’s public transport brings the internet joy and confusion in equal measure – but nothing compares to experiencing it yourself.

          Platform markings show exactly where the doors will open, lightning-fast shinkansen cleaning crews spruce up the carriages in a flash and friendly, helpful staff go out of their way to give you advice. You’ll almost certainly get lost in the maze of lines zigzagging across the city, or in the labyrinthine Tokyo station, but that’s all part of the fun. Do avoid Shinjuku Station at rush hour though – it’s the world’s busiest, with over 3.5 million people passing through it every day.        </p>

        <p>
          Three of the six major sumo tournaments (honbasho) of the year are held in Tokyo, so you’ve got more chance of seeing these giants clashing here than in any other city in Japan.

          Head (with your pre-booked tickets) to the huge sumo hall in Ryogoku early in the day. It’ll be fairly empty as the smaller matches take place, so you can sneak down nearer the ring to watch. As the day progresses and the enormous hall fills, the clashes get shorter, the build-up longer and the fighters bigger.

          By the time you reach the highest levels of the sport, the crowd is in a frenzy. Minutes of stamping, salt-throwing and intimidation between the wrestlers ratchets up the tension, until finally, they fight – and, usually within a few seconds, one will emerge victorious.        </p>
        <p>
          Japan is a very homogenous country, for better or worse. But in cosmopolitan Tokyo (and Yokohama, which bleeds into the same metropolitan area) you’ll have plenty of chance to see other cultures mixed in with Japanese.

          Check out Shin-Okubo for a slice of Seoul in Tokyo – it’s worth the trip if only for the street food, like warm hottoku (hotteok in Korean) pancakes filled with melting brown sugar and cinnamon. Or head to Nishi Kaisai if you’re after a proper Indian curry; the Japanese version (kare raisu) is delicious, but as they got the original recipe from the Brits, it’s hardly authentic. A short trip away, in Yokohama, is Japan’s biggest Chinatown, which you should visit for the steamed buns alone.

          Beyond these enclaves, the whole city is much more diverse than the rest of the country. From British-style pubs to Jamaican-themed cafés, you’ll find endless, fascinating mixes of Japanese and foreign cultures here.        </p>
        <figure>
          {/* <img
            src="https://images.unsplash.com/photo-1501493870936-9c2e41625521?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=1045&amp;q=80"
            alt="nc blog"
            className="rounded-2xl"
          /> */}
          {/* <figcaption>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Iure vel
            officiis ipsum placeat itaque neque dolorem modi perspiciatis dolor
            distinctio veritatis sapiente
          </figcaption> */}
        </figure>

      </div>
    );
  };

  const renderTags = () => {
    return (
      <div className="max-w-screen-md mx-auto flex flex-wrap">
        <a
          className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
          href="##"
        >
          Garden
        </a>
        <a
          className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
          href="##"
        >
          Jewelry
        </a>
        <a
          className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
          href="##"
        >
          Tools
        </a>
      </div>
    );
  };

  const renderAuthor = () => {
    return (
      <div className="max-w-screen-md mx-auto ">
        <div className="nc-SingleAuthor flex">
          <Avatar sizeClass="w-11 h-11 md:w-24 md:h-24" />
          <div className="flex flex-col ml-3 max-w-lg sm:ml-5 space-y-1">
            <span className="text-xs text-neutral-400 uppercase tracking-wider">
              WRITEN BY
            </span>
            <h2 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
              <a href="/ncmaz/author/the-demo-author-slug">Fones Mimi</a>
            </h2>
            <span className="text-sm text-neutral-500 sm:text-base dark:text-neutral-300">
              There’s no stopping the tech giant. Apple now opens its 100th
              store in China.There’s no stopping the tech giant.
              <a
                className="text-primary-6000 font-medium ml-1"
                href="/ncmaz/author/the-demo-author-slug"
              >
                Readmore
              </a>
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderCommentForm = () => {
    return (
      <div className="max-w-screen-md mx-auto pt-5">
        <h3 className="text-xl font-semibold text-neutral-800 dark:text-neutral-200">
          Responses (14)
        </h3>
        <form className="nc-SingleCommentForm mt-5">
          <Textarea />
          <div className="mt-2 space-x-3">
            <ButtonPrimary>Submit</ButtonPrimary>
            <ButtonSecondary>Cancel</ButtonSecondary>
          </div>
        </form>
      </div>
    );
  };

  const renderCommentLists = () => {
    return (
      <div className="max-w-screen-md mx-auto">
        <ul className="nc-SingleCommentLists space-y-5">
          <li>
            <Comment />
            <ul className="pl-4 mt-5 space-y-5 md:pl-11">
              <li>
                <Comment isSmall />
              </li>
            </ul>
          </li>
          <li>
            <Comment />
            <ul className="pl-4 mt-5 space-y-5 md:pl-11">
              <li>
                <Comment isSmall />
              </li>
            </ul>
          </li>
        </ul>
      </div>
    );
  };

  const renderPostRelated = (post: PostDataType) => {
    return (
      <div
        key={post.id}
        className="relative aspect-w-3 aspect-h-4 rounded-3xl overflow-hidden group"
      >
        <Link to={post.href} />
        <NcImage
          className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-300"
          src={post.featuredImage}
        />
        <div>
          <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
        </div>
        <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-2.5 p-4">
          <Badge name="Categories" />
          <h2 className="block text-lg font-semibold text-white ">
            <span className="line-clamp-2">{post.title}</span>
          </h2>

          <div className="flex">
            <span className="block text-neutral-200 hover:text-white font-medium truncate">
              {post.author.displayName}
            </span>
            <span className="mx-1.5 font-medium">·</span>
            <span className="font-normal truncate">{post.date}</span>
          </div>
        </div>
        <Link to={post.href} />
      </div>
    );
  };

  return (
    <div className="nc-PageSingle pt-8 lg:pt-16 ">
      <Helmet>
        <title>Explore Tokyo || Travel 7 World</title>
      </Helmet>
      {renderHeader()}
      <NcImage
        className="w-full rounded-xl"
        containerClassName="container my-10 sm:my-12 "
        src="https://images.pexels.com/photos/4151484/pexels-photo-4151484.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260"
      />

      <div className="nc-SingleContent container space-y-10">
        {renderContent()}
        {/* {renderTags()} */}
        <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
        {/* {renderAuthor()} */}
        {/* {renderCommentForm()} */}
        {/* {renderCommentLists()} */}
      </div>
      {/* <div className="relative bg-neutral-100 dark:bg-neutral-800 py-16 lg:py-28 mt-16 lg:mt-24"> */}
      {/* <div className="container "> */}
      {/* <h2 className="text-3xl font-semibold">Related posts</h2> */}
      {/* <div className="mt-10 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8"> */}
      {/*  */}
      {/* {DEMO_POSTS.filter((_, i) => i < 4).map(renderPostRelated)} */}
      {/*  */}
      {/* </div> */}
      {/* </div> */}
      {/* </div> */}
    </div>
  );
};

export default BlogSingleTokyo;
