import { ActionType } from 'state/action-types'
import { Action, PossibleOfferObject } from '../actions/index'

const initialState = {
    offerObject: <PossibleOfferObject>(null)
}

const getOfferObjectReducer = (state = initialState, action: Action) => {

    if (action.type == ActionType.GET_OFFERS) {
        let newOfferObject = action.payload
        return {
            ...state,
            offerObject: newOfferObject
        }
    } else {
        return state;
    }
}

export default getOfferObjectReducer;