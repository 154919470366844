import { legacy_createStore, applyMiddleware } from 'redux';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistStore } from 'redux-persist';
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import thunk from "redux-thunk";

import rootReducer from './reducers/rootReducer';

const persistConfig = {
    key: 'persist-key',
    storage
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = legacy_createStore(
    persistedReducer,
    {},
    applyMiddleware(thunk));


export default store;
export const persistor = persistStore(store);
