import { combineReducers } from 'redux';
import getOfferRequestReducer from './getOfferRequestReducer';
import getOfferObject from './getOfferObjectReducer';
import storeFlightDataOneReducer from './storeFlightDataOneReducer'
import storeFlightDataTwoReducer from './storeFlightDataTwoReducer';
import storeCustomerDataReducer from './storeCustomerDataReducer'
import getBasicDetailsReducer from './getBasicDetailsReducer';
import getReturnOfferObjectReducer from './getReturnOfferObjectReducer';
import storeLoginReducer from './storeLoginReducer';
import changeCurrencyReducer from './changeCurrencyReducer'
import setLoadReducer from './setLoadReducer';


const rootReducer = combineReducers({
    getOfferRequestReducer: getOfferRequestReducer,
    getOfferObject: getOfferObject,
    storeFlightDataOneReducer: storeFlightDataOneReducer,
    storeFlightDataTwoReducer: storeFlightDataTwoReducer,
    storeCustomerDataReducer: storeCustomerDataReducer,
    getBasicDetailsReducer: getBasicDetailsReducer,
    getReturnOfferObjectReducer: getReturnOfferObjectReducer,
    storeLoginReducer: storeLoginReducer,
    changeCurrencyReducer: changeCurrencyReducer,
    setLoadReducer: setLoadReducer
})

export default rootReducer;

export type State = ReturnType<typeof rootReducer>