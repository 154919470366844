import { ActionType } from 'state/action-types'
import { Action, PossibleOfferObject } from '../actions/index'

const initialState = {
    returnOfferObject: <PossibleOfferObject>(null)
}

const getReturnOfferObjectReducer = (state = initialState, action: Action) => {
    if (action.type === ActionType.GET_RETURN_OFFERS) {
        let newReturnOfferObject = action.payload

        return {
            ...state,
            returnOfferObject: newReturnOfferObject
        }
    } else {
        return state;
    }
}

export default getReturnOfferObjectReducer;