import React, { FC, useState, useEffect } from "react";
import { parse, end, toSeconds, pattern } from "iso8601-duration";
import dateFormat, { masks } from "dateformat";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux"
import { bindActionCreators } from "redux";
import { actionCreators, State } from "state";
import { PossibleOfferObject } from "state/actions";
import { MoonLoader } from "react-spinners";
import { getReturnOffers } from "state/action-creators";
import { Offer } from "dataTypes/dataTypes";

export interface FlightCardPropsReturn {
  className?: string;
  returnType?: string;
  flightData: Offer
}

export interface Duration {
  years?: number | string,
  months?: number | string,
  weeks?: number | string,
  days?: number | string,
  hours?: number | string,
  minutes?: number | string,
  seconds?: number | string
}

const FlightCardReturn: FC<FlightCardPropsReturn> = ({ className = "", flightData }) => {

  var secondOfferRequestJSON: PossibleOfferObject;


  const [isOpen, setIsOpen] = useState(false);
  const [flightDataTwoObject, setFlightDataTwoObject] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  const [layover, setLayover] = useState(false);


  var statusCode = 0;



  const history = useHistory();

  const dispatch = useDispatch();
  const { getReturnOffers } = bindActionCreators(actionCreators, dispatch);
  const { storeFlightDataOne, setLoad } = bindActionCreators(actionCreators, dispatch);
  const state = useSelector((state: State) => state.storeFlightDataOneReducer)
  const basicFlightState = useSelector((state: State) => state.getBasicDetailsReducer)
  const loadState = useSelector((state: State) => state.setLoadReducer)



  useEffect(() => {
    if (flightData.slices[0].segments.length > 1) {
      setLayover(true)
    } else {
      setLayover(false)
    }
  }, []);

  const formatDuration = (duration: Duration) => {

    var years = duration.years
    var months = duration.months
    var weeks = duration.weeks
    var days = duration.days
    var hours = duration.hours
    var minutes = duration.minutes
    var seconds = duration.seconds

    if (years == "0") {
      years = ""
    } else {
      years = years + " years "
    }

    if (months == "0") {
      months = ""
    } else {
      months = months + " months "
    }

    if (weeks == "0") {
      weeks = ""
    } else {
      weeks = weeks + " weeks "
    }

    if (days == "0") {
      days = ""
    } else {
      days = days + " day "
    }

    if (hours == "0") {
      hours = ""
    } else {
      hours = hours + " hours "
    }

    if (minutes == "0") {
      minutes = ""
    } else {
      minutes = minutes + " minutes "
    }

    if (seconds == "0") {
      seconds = ""
    } else {
      seconds = seconds + " seconds "
    }

    return years + months + weeks + days + hours + minutes + seconds
  }


  var arriving_at_time = ""
  var arriving_at_simple_time = ""
  var layover_origin = "";
  var layover_airport = "";
  var layover_arrival = "";
  var layover_departure = "";
  var arriving_at_date = "";
  var layover_city = "";
  var layoverDiff = "";
  var layover_operating_carrier_code = "";
  var layover_flight_number = "";
  var layover_aircraft_name = "Airbus A321";
  var aircraft_name = "Airbus A321";

  const formatDates = (d1: Date, d2: Date) => {
    var diff = Math.abs((d1).getTime() - (d2).getTime()) / 36e5
    var hours = Math.floor(diff);
    var minutes = Math.round((diff - hours) * 60)

    if (hours > 0 && minutes > 0) {
      return hours.toString() + " hours and " + minutes.toString() + " minutes"
    } else if (hours === 0 && minutes > 0) {
      return minutes.toString() + " minutes"
    } else {
      return hours.toString() + " hours"
    }
  }

  // console.log(flightData)

  var airline_logo = flightData.slices[0].segments[0].marketing_carrier.logo_symbol_url
  var departing_at_date = dateFormat(flightData.slices[0].segments[0].departing_at, "mmmm dS, yyyy h:MM TT")
  var origin_airport_name = flightData.slices[0].origin.name
  var origin_airport_code = flightData.slices[0].origin.iata_code
  var arriving_at_date = dateFormat(flightData.slices[0].segments[0].arriving_at, "mmmm dS, yyyy h:MM TT")
  var destination_airport_name = flightData.slices[0].destination.name
  var destination_airport_code = flightData.slices[0].destination.iata_code
  var flight_duration = formatDuration(parse(flightData.slices[0].duration))
  var operating_carrier_code = flightData.slices[0].segments[0].marketing_carrier.iata_code
  var fare_type = flightData.slices[0].fare_brand_name

  if (flightData.slices[0].segments[0].aircraft !== null) {
    var aircraft_name = flightData.slices[0].segments[0].aircraft.name
  }

  var flight_number = flightData.slices[0].segments[0].marketing_carrier_flight_number
  var departing_at_time = dateFormat(flightData.slices[0].segments[0].departing_at, "h:MM:ss TT")
  var fare_basis_code = flightData.slices[0].segments[0].passengers[0].fare_basis_code
  if (layover === true) {
    arriving_at_time = dateFormat(flightData.slices[0].segments[1].arriving_at, "h:MM:ss TT")
    arriving_at_simple_time = dateFormat(flightData.slices[0].segments[1].arriving_at, "h:MM TT")
    layover_origin = flightData.slices[0].segments[0].destination.name
    layover_airport = flightData.slices[0].segments[0].destination.iata_code
    layover_arrival = dateFormat(flightData.slices[0].segments[0].arriving_at, "mmmm dS, yyyy h:MM TT")
    layover_departure = dateFormat(flightData.slices[0].segments[1].departing_at, "mmmm dS, yyyy h:MM TT")
    arriving_at_date = dateFormat(flightData.slices[0].segments[1].arriving_at, "mmmm dS, yyyy h:MM TT")
    layover_city = flightData.slices[0].segments[0].destination.city_name
    layoverDiff = formatDates(new Date(flightData.slices[0].segments[1].departing_at), new Date(flightData.slices[0].segments[0].arriving_at));
    layover_operating_carrier_code = flightData.slices[0].segments[1].marketing_carrier.iata_code
    layover_flight_number = flightData.slices[0].segments[1].marketing_carrier_flight_number
    if (flightData.slices[0].segments[1].aircraft !== null) {
      layover_aircraft_name = flightData.slices[0].segments[1].aircraft.name
    }
  } else {
    arriving_at_time = dateFormat(flightData.slices[0].segments[0].arriving_at, "h:MM:ss TT")
    arriving_at_simple_time = dateFormat(flightData.slices[0].segments[0].arriving_at, "h:MM TT")
    arriving_at_date = dateFormat(flightData.slices[0].segments[0].arriving_at, "mmmm dS, yyyy h:MM TT")
  }
  var departing_at_simple_time = dateFormat(flightData.slices[0].segments[0].departing_at, "h:MM TT")
  var flight_subtotal = flightData.base_amount
  var flight_tax_price: number = Math.round(flightData.tax_amount * 0.50) // PRICE FACTOR
  var flight_total_price: number = Math.round(flightData.total_amount * 0.50) // PRICE FACTOR
  if (flight_total_price < 110) {
    var price_factor = flight_total_price
    flight_total_price = Math.floor(Math.log((price_factor / 100) + 1) * (230 - 181) + 181);
  }
  var flight_currency = "USD"
  var number_of_passengers = flightData.passengers.length
  var number_of_passengers_array = flightData.passengers
  var number_of_adults = 0
  var number_of_children = 0
  var number_of_infants = 0
  var origin_city = flightData.slices[0].origin.city_name
  var destination_city = flightData.slices[0].destination.city_name
  var airline_name = flightData.owner.name

  //EXCLUSIVE RETURN ITEMS

  // var airline_logo_ret = flightData.slices[1].segments[0].operating_carrier.logo_symbol_url
  // var departing_at_date_ret = dateFormat(flightData.slices[1].segments[0].departing_at, "mmmm dS, yyyy h:MM TT")
  // var origin_airport_name_ret = flightData.slices[1].origin.name
  // var origin_airport_code_ret = flightData.slices[1].origin.iata_code
  // var arriving_at_date_ret = dateFormat(flightData.slices[1].segments[0].arriving_at, "mmmm dS, yyyy h:MM TT")
  // var destination_airport_name_ret = flightData.slices[1].destination.name
  // var destination_airport_code_ret = flightData.slices[1].destination.iata_code
  // var flight_duration_ret = formatDuration(parse(flightData.slices[1].duration))
  // var operating_carrier_code_ret = flightData.slices[1].segments[0].operating_carrier.iata_code
  // var fare_type_ret = flightData.slices[1].fare_brand_name
  // var aircraft_name_ret = flightData.slices[1].segments[0].aircraft.name
  // var flight_number_ret = flightData.slices[1].segments[0].operating_carrier_flight_number
  // var departing_at_time_ret = dateFormat(flightData.slices[1].segments[0].departing_at, "h:MM:ss TT")
  // var arriving_at_time_ret = dateFormat(flightData.slices[1].segments[0].arriving_at, "h:MM:ss TT")
  // var departing_at_simple_time_ret = dateFormat(flightData.slices[1].segments[0].departing_at, "h:MM TT")
  // var arriving_at_simple_time_ret = dateFormat(flightData.slices[1].segments[0].arriving_at, "h:MM TT")


  async function sleep(msec: any) {
    return new Promise(resolve => setTimeout(resolve, msec));
  }


  for (var i = 0; i < number_of_passengers; i++) {
    if (flightData.passengers[i].type == "adult") {
      number_of_adults++
    }
  }

  for (var i = 0; i < number_of_passengers; i++) {
    if (flightData.passengers[i].type == "child") {
      number_of_children++
    }
  }

  for (var i = 0; i < number_of_passengers; i++) {
    if (flightData.passengers[i].type == "infant_without_seat") {
      number_of_infants++
    }
  }



  var flightObjectOne = {
    airline_logo: airline_logo,
    departing_at_date: departing_at_date,
    origin_airport_name: origin_airport_name,
    origin_airport_code: origin_airport_code,
    arriving_at_date: arriving_at_date,
    destination_airport_name: destination_airport_name,
    destination_airport_code: destination_airport_code,
    flight_duration: flight_duration,
    operating_carrier_code: operating_carrier_code,
    fare_type: fare_type,
    aircraft_name: aircraft_name,
    flight_number: flight_number,
    departing_at_time: departing_at_time,
    arriving_at_time: arriving_at_time,
    departing_at_simple_time: departing_at_simple_time,
    arriving_at_simple_time: arriving_at_simple_time,
    flight_subtotal: flight_subtotal,
    flight_tax_price: flight_tax_price,
    flight_total_price: flight_total_price,
    flight_currency: flight_currency,
    number_of_passengers: number_of_passengers,
    number_of_passengers_array: number_of_passengers_array,
    number_of_adults: number_of_adults,
    number_of_children: number_of_children,
    number_of_infants: number_of_infants,
    origin_city: origin_city,
    destination_city: destination_city,
    airline_name: airline_name,
    // airline_logo_ret: airline_logo_ret,
    // departing_at_date_ret: departing_at_date_ret,
    // origin_airport_name_ret: origin_airport_name_ret,
    // origin_airport_code_ret: origin_airport_code_ret,
    // arriving_at_date_ret: arriving_at_date_ret,
    // destination_airport_name_ret: destination_airport_name_ret,
    // destination_airport_code_ret: destination_airport_code_ret,
    // flight_duration_ret: flight_duration_ret,
    // operating_carrier_code_ret: operating_carrier_code_ret,
    // fare_type_ret: fare_type_ret,
    // aircraft_name_ret: aircraft_name_ret,
    // flight_number_ret: flight_number_ret,
    // departing_at_time_ret: departing_at_time_ret,
    // arriving_at_time_ret: arriving_at_time_ret,
    // departing_at_simple_time_ret: departing_at_simple_time_ret,
    // arriving_at_simple_time_ret: arriving_at_simple_time_ret,
    return_type: "roundTrip",
    layover: layover,
    layover_origin: layover_origin,
    layover_airport: layover_airport,
    layover_arrival: layover_arrival,
    layover_departure: layover_departure,
    layover_city: layover_city,
    layover_operating_carrier_code: layover_operating_carrier_code,
    layover_flight_number: layover_flight_number,
    layoverDiff: layoverDiff,
    fare_basis_code: fare_basis_code
  };





  const handleSubmit = async () => {

    var destination = basicFlightState.basicDetailsObject?.departure
    var departure = basicFlightState.basicDetailsObject?.destination
    var returnDate = basicFlightState.basicDetailsObject?.departureDate
    var departureDate = basicFlightState.basicDetailsObject?.returnDate
    var returnType = basicFlightState.basicDetailsObject?.returnType
    var flightCabinClass = basicFlightState.basicDetailsObject?.flightCabinClass
    var passengerList = basicFlightState.basicDetailsObject?.passengerList

    setLoad(true)

    try {
      // const res = await fetch('https://hereflight.com/api/search', {
      //   method: 'post',
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify({
      //     departure,
      //     destination,
      //     departureDate,
      //     returnDate,
      //     returnType,
      //     flightCabinClass,
      //     passengerList
      //   }),
      // })
      //   .then(async response => {
      //     secondOfferRequestJSON = await response.json();
      //     statusCode = response.status;
      //   })

      await sleep(7000);

      // getReturnOffers(secondOfferRequestJSON)
      storeFlightDataOne(flightObjectOne)



      setLoad(false)
      // if (statusCode == 200) {
      history.push('/choose-return-flights')
      // }
    } catch (e) {
      history.push('/error')
    }


  }



  const renderOneWay = () => {


    { loadState.load === true ? document.body.style.opacity = "0.4" : document.body.style.opacity = "1" }
    { loadState.load === true ? document.body.style.pointerEvents = "none" : document.body.style.pointerEvents = "auto" }

    return (



      <div className="p-4 md:p-8 border border-neutral-200 dark:border-neutral-700 rounded-2xl ">
        {isLoading === true ?
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
            <MoonLoader size={300} color="#161459" />
          </div> : <div />}
        <div>
          <div className="flex flex-col md:flex-row ">
            <div className="w-24 md:w-20 lg:w-24 flex-shrink-0 md:pt-7">
              <img src={airline_logo} className="w-10" alt="" />
            </div>
            <div className="flex my-5 md:my-0">
              <div className="flex-shrink-0 flex flex-col items-center py-2">
                <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
                <span className="block flex-grow border-l border-neutral-400 border-dashed my-1"></span>
                <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
              </div>
              <div className="ml-4 space-y-10 text-sm">
                <div className="flex flex-col space-y-1">
                  <span className=" text-neutral-500 dark:text-neutral-400">
                    {departing_at_date}
                  </span>
                  <span className=" font-semibold">
                    {origin_airport_name} {(origin_airport_code)}
                  </span>
                </div>
                <div className="flex flex-col space-y-1">
                  <span className=" text-neutral-500 dark:text-neutral-400">
                    {arriving_at_date}
                  </span>
                  <span className=" font-semibold">
                    {destination_airport_name} {(destination_airport_code)}
                  </span>
                </div>
              </div>
            </div>
            <div className="border-l border-neutral-200 dark:border-neutral-700 md:mx-6 lg:mx-10"></div>
            <ul className="text-sm text-neutral-500 dark:text-neutral-400 space-y-1 md:space-y-2">
              <li>Trip time: {flight_duration}</li>
              <li>{operating_carrier_code} {flight_number} · {fare_type} · {aircraft_name}</li>
              <div>Fare Basis Code: {fare_basis_code}</div>
            </ul>
            <div className="border-l border-neutral-200 dark:border-neutral-700 md:mx-6 lg:mx-10">
              <div className="text-sm text-neutral-500 dark:text-neutral-400 space-y-1 md:space-y-2 ml-4">
                {airline_name}
              </div>
            </div>
            <span className="ml-40" >
              <ButtonPrimary onClick={handleSubmit}>
                CHOOSE RETURN FLIGHT
              </ButtonPrimary>
            </span>
          </div>
        </div>
      </div>
    );
  };




  const renderLayover = () => {

    { isLoading ? document.body.style.opacity = "0.4" : document.body.style.opacity = "1" }
    { isLoading ? document.body.style.pointerEvents = "none" : document.body.style.pointerEvents = "auto" }

    return (
      <div>
        <div className="p-4 md:p-8 border border-neutral-200 dark:border-neutral-700 rounded-2xl ">
          {isLoading ?
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
              <MoonLoader size={300} color="#161459" />
            </div> : <div />}
          <div>
            <div className="flex flex-col md:flex-row ">
              <div className="w-24 md:w-20 lg:w-24 flex-shrink-0 md:pt-7">
                <img src={airline_logo} className="w-10" alt="" />
              </div>
              <div className="flex my-5 md:my-0">
                <div className="flex-shrink-0 flex flex-col items-center py-2">
                  <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
                  <span className="block flex-grow border-l border-neutral-400 border-dashed my-1"></span>
                  <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
                </div>
                <div className="ml-4 space-y-10 text-sm">
                  <div className="flex flex-col space-y-1">
                    <span className=" text-neutral-500 dark:text-neutral-400">
                      {departing_at_date}
                    </span>
                    <span className=" font-semibold">
                      {origin_airport_name} {(origin_airport_code)}
                    </span>
                  </div>
                  {/* LAYOVER PART */}
                  <div className="flex flex-col space-y-1">
                    <span className=" text-neutral-500 dark:text-neutral-400">
                      {layover_arrival}
                    </span>
                    <span className=" font-semibold">
                      {layover_origin} {(layover_airport)}
                    </span>
                    <span className=" text-neutral-500 dark:text-neutral-400">
                      {layover_departure}
                    </span>
                  </div>
                  <div className="flex flex-col space-y-1">
                    <span className=" text-neutral-500 dark:text-neutral-400">
                      {arriving_at_date}
                    </span>
                    <span className=" font-semibold">
                      {destination_airport_name} {(destination_airport_code)}
                    </span>
                  </div>
                </div>
              </div>
              <div className="border-l border-neutral-200 dark:border-neutral-700 md:mx-6 lg:mx-10"></div>
              <ul className="text-sm text-neutral-500 dark:text-neutral-400 space-y-1 md:space-y-2">
                <li>Trip time: {flight_duration}</li>
                {layover === true ? <div><li>{operating_carrier_code} {flight_number} · {fare_type} · {aircraft_name}</li>
                  <li className="mt-8">{layover_operating_carrier_code} {layover_flight_number} · {fare_type} · {layover_aircraft_name}</li></div> :
                  <li>{operating_carrier_code} {flight_number} · {fare_type} · {aircraft_name}</li>}
                <div>Fare Basis Code: {fare_basis_code}</div>
              </ul>
              <div className="border-l border-neutral-200 dark:border-neutral-700 md:mx-6 lg:mx-10">
                <div className="text-sm text-neutral-500 dark:text-neutral-400 space-y-1 md:space-y-2 ml-4">
                  {airline_name}
                </div>
              </div>
              <span className="ml-40" >
                <ButtonPrimary onClick={handleSubmit}>
                  CHOOSE RETURN FLIGHT
                </ButtonPrimary>
              </span>
            </div>
          </div>
        </div>
      </div>

    );
  };



  const renderDetail = () => {
    if (!isOpen) return null;
    return (
      // <div className="p-4 md:p-8 border border-neutral-200 dark:border-neutral-700 rounded-2xl ">
      //   {renderDetailTop()}
      //   <div className="my-7 md:my-10 space-y-5 md:pl-24">
      //     <div className="border-t border-neutral-200 dark:border-neutral-700" />
      //     <div className="text-neutral-700 dark:text-neutral-300 text-sm md:text-base">
      //       Transit time: 15 hours 45 minutes - Bangkok (BKK)
      //     </div>
      //     <div className="border-t border-neutral-200 dark:border-neutral-700" />
      //   </div>

      // </div>
      <div>
        {layover == true ? renderLayover() : renderOneWay()}
      </div>
    );
  };

  return (
    <div
      className={`nc-FlightCardgroup p-4 sm:p-6 relative bg-white dark:bg-neutral-900 border border-neutral-100
     dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-lg transition-shadow space-y-6 ${className}`}
      data-nc-id="FlightCard"
    >
      <div
        className={` sm:pr-20 relative  ${className}`}
        data-nc-id="FlightCard"
      >
        {/*  eslint-disable-next-line jsx-a11y/anchor-has-content */}
        {/* <a href="##" className="absolute inset-0" /> */}

        <span
          className={`absolute right-0 bottom-0 sm:bottom-auto sm:top-1/2 sm:-translate-y-1/2 w-10 h-10 bg-neutral-50 dark:bg-neutral-800 rounded-full flex items-center justify-center cursor-pointer ${isOpen ? "transform -rotate-180" : ""
            }`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <i className="text-xl las la-angle-down"></i>
        </span>

        <div className="flex  flex-col sm:flex-row sm:items-center space-y-6 sm:space-y-0">
          {/* LOGO IMG */}
          <div className="w-24 lg:w-32 flex-shrink-0">
            <img src={airline_logo} className="w-10" alt="" />
          </div>

          {/* FOR MOBILE RESPONSIVE */}
          <div className="block lg:hidden space-y-1">
            <div className="flex font-semibold">
              <div>
                {departing_at_time}
                <span></span>
                <span className="flex items-center text-sm text-neutral-500 font-normal mt-0.5">
                  {origin_airport_code}
                </span>
              </div>
              <span className="w-12 flex justify-center">
                <i className=" text-2xl las la-long-arrow-alt-right"></i>
              </span>
              <div>
                <span>
                  {arriving_at_time}
                </span>
                <span className="flex items-center text-sm text-neutral-500 font-normal mt-0.5">
                  {destination_airport_code}
                </span>
              </div>
            </div>

            <div className="text-sm text-neutral-500 font-normal mt-0.5">
              <span className="VG3hNb">Nonstop</span>
              <span className="mx-2">·</span>
              <span>{flight_duration}</span>
              <span className="mx-2">·</span>
              <span>HAN</span>
            </div>
          </div>

          {/* TIME - NAME */}
          <div className="hidden lg:block  min-w-[150px] flex-[4] ">
            <div className="font-medium text-lg">
              {departing_at_simple_time} - {arriving_at_simple_time}
            </div>
            <div className="text-sm text-neutral-500 font-normal mt-0.5">
              {/* {data.airlines.name} */}
            </div>
          </div>

          {/* TIMME */}
          <div className="hidden lg:block flex-[4] whitespace-nowrap">
            <div className="font-medium text-lg">{origin_airport_code} - {destination_airport_code}</div>
            <div className="text-sm text-neutral-500 font-normal mt-0.5">
              {flight_duration}
            </div>
          </div>

          {/* TYPE // LAYOVER TYPE SHIT */}
          <div className="hidden lg:block flex-[4] whitespace-nowrap">
            {layover == true ? <div className="font-medium text-lg">1 stop</div> : <div className="font-medium text-lg">Direct</div>}
            <div className="text-sm text-neutral-500 font-normal mt-0.5">
              {/* 2 hours 15 minutes BKK */}
              {layover == true ? <div className="font-medium text-lg">{layoverDiff} stop</div> : <div />}
            </div>
          </div>

          {/* PRICE */}
          <div className="flex-[4] whitespace-nowrap sm:text-right">
            <div>
              <span className="text-xl font-semibold text-secondary-6000">
                {flight_total_price} {flight_currency}
              </span>
            </div>
            <div className="text-xs sm:text-sm text-neutral-500 font-normal mt-0.5">
              round-trip
            </div>
          </div>
        </div>
      </div>

      {/* DETAIL */}
      {renderDetail()}
    </div>
  );
};

export default FlightCardReturn;
