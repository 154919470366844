import React, { useEffect, useState } from "react";
import LocationInput from "./LocationInput";
import { FocusedInputShape } from "react-dates";
import RentalCarDatesRangeInput from "./RentalCarDatesRangeInput";
import ButtonSubmit from "./ButtonSubmit";
import { FC } from "react";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon, SearchCircleIcon } from "@heroicons/react/solid";
import { Fragment } from "react";
import moment from "moment";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import { useHistory } from "react-router-dom";
import { connect, ConnectedProps } from 'react-redux';
import { useDispatch, useSelector } from "react-redux"
import { bindActionCreators } from "redux";
import { actionCreators, State } from "state";
import { PossibleOfferRequestObject } from "state/actions";
import { PossibleOfferObject } from "state/actions";
import { MoonLoader, ClipLoader, GridLoader } from "react-spinners";


export interface DateRage {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
}

export interface TimeRage {
  startTime: string;
  endTime: string;
}

type SearchFormProps = ConnectedProps<typeof connector>;

export interface FlightSearchFormProps {
  haveDefaultValue?: boolean;
}

const flightClass = [
  {
    name: "Economy",
    href: "##",
    cabin_class: "economy"
  },
  {
    name: "Premium Economy",
    href: "##",
    cabin_class: "premium_economy"

  },
  {
    name: "Business",
    href: "##",
    cabin_class: "business"
  },
  {
    name: "First Class",
    href: "##",
    cabin_class: "first"
  },
];

const FlightSearchForm: FC<FlightSearchFormProps> = ({
  haveDefaultValue
}) => {


  const history = useHistory();
  const dispatch = useDispatch();

  // const { getOfferRequest } = bindActionCreators(actionCreators, dispatch);
  // const state = useSelector((state: State) => state.getOfferRequestReducer)

  const { getOffers, getBasicDetails, setLoad } = bindActionCreators(actionCreators, dispatch);

  const state = useSelector((state: State) => state.getOfferObject)
  const loadState = useSelector((state: State) => state.setLoadReducer)


  // DEFAULT DATA FOR ARCHIVE PAGE
  const defaultPickUpInputValue = "";
  const defaultDropOffInputValue = "";

  // USE STATE
  const [dateRangeValue, setDateRangeValue] = useState<DateRage>({
    startDate: moment(),
    endDate: moment().add(1, "days"),
  });
  const [timeRangeValue, setTimeRangeValue] = useState<TimeRage>({
    startTime: "10:00 AM",
    endTime: "10:00 AM",
  });
  const [pickUpInputValue, setPickUpInputValue] = useState("");
  const [dropOffInputValue, setDropOffInputValue] = useState("");
  const [fieldFocused, setFieldFocused] = useState<
    FocusedInputShape | "dropOffInput" | null
  >(null);
  const [dropOffLocationType, setDropOffLocationType] = useState<
    "roundTrip" | "oneWay" | ""
  >("roundTrip");
  const [adults, setAdults] = useState(1);
  const [youngAdults, setYoungAdults] = useState(0);
  const [children, setChildren] = useState(0);
  const [infants, setInfants] = useState(0);

  const [adultArray, setAdultArray] = useState([1]);
  const [childArray, setChildArray] = useState([0]);
  const [infantArray, setInfantArray] = useState([0]);

  const [paList, setPaList] = useState({ passengers: [{ "type": "adult" }] });



  const [validDestination, setValidDestination] = useState(true)


  const [flightClassState, setFlightClassState] = useState("Economy");
  const [cabinClass, setCabinClass] = useState("economy");


  const passengerCount = adults + children + infants



  var passengerList = { passengers: [{ "type": "adult" }] };



  // USER EFFECT
  useEffect(() => {
    setLoad(false);

    if (haveDefaultValue) {
      setDateRangeValue({
        startDate: moment(),
        endDate: moment().add(4, "days"),
      });

      setPickUpInputValue(defaultPickUpInputValue);
      setDropOffInputValue(defaultDropOffInputValue);
    }
  }, []);
  //

  const departureDate = dateRangeValue.startDate?.format("DD-MM-YYYY");


  var statusCode = 0;

  // var offerRequestJSON: PossibleOfferRequestObject;
  var offerRequestJSON: PossibleOfferObject;

  const handleSubmit = async (e: { preventDefault: () => void; }) => {

    setLoad(true);

    for (var i = adultArray[0]; i > 1; i--) {
      passengerList.passengers.push({ "type": "adult" })

    }

    for (var i = childArray[0]; i > 0; i--) {
      passengerList.passengers.push({ "type": "child" })
    }

    for (var i = infantArray[0]; i > 0; i--) {
      passengerList.passengers.push({ "type": "infant_without_seat" })
    }


    // Resolutions
    var departure = pickUpInputValue;
    var destination = dropOffInputValue;
    var departureDate = dateRangeValue.startDate?.format("YYYY-MM-DD");
    var returnDate = dateRangeValue.endDate?.format("YYYY-MM-DD");
    var returnType = dropOffLocationType;
    var flightCabinClass = cabinClass;


    var basicFlightDetails = {
      departure: departure,
      destination: destination,
      departureDate: departureDate,
      returnDate: returnDate,
      returnType: returnType,
      flightCabinClass: flightCabinClass,
      passengerList: passengerList
    }

    e.preventDefault();

    try {
      const res = await fetch('https://travel7world.com/api/search', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          departure,
          destination,
          departureDate,
          returnDate,
          returnType,
          flightCabinClass,
          passengerList
        }),
      })
        // .then(response => statusCode = response.status)
        .then(async response => {
          offerRequestJSON = await response.json();
          statusCode = response.status;
        })

      // console.log(offerRequestJSON)

      getOffers(offerRequestJSON)
      getBasicDetails(basicFlightDetails)
      setLoad(false);


      if (statusCode == 200 && returnType == "oneWay" && offerRequestJSON?.offer.length! > 0) {
        history.push('/listing-flights')
      } else if (statusCode == 200 && returnType == "roundTrip" && offerRequestJSON?.offer.length! > 0) {
        history.push('/return-flights')
      } else if (offerRequestJSON?.offer.length! == 0) {
        alert("No flights for " + departure + " to " + destination + " found.")
      } else if (departure === destination) {
        alert("Departure location and destination cannot be the same!")
      } else {
        alert("No flights for " + departure + " to " + destination + " found.")
        history.push('/error')
      }

    } catch (e) {
      if (departure === destination) {
        alert("Departure location and destination cannot be the same!")
        history.push('/')
      } else {
        history.push('/error')
      }
    }

    passengerList = { passengers: [{ "type": "adult" }] };

  };


  // } else if (offerRequestJSON?.offer.length! == 0) {
  //   alert("No flights for " + departure + " to " + destination + " found.")

  const renderGuest = () => {

    { loadState.load === true ? document.body.style.opacity = "0.4" : document.body.style.opacity = "1" }
    { loadState.load === true ? document.body.style.pointerEvents = "none" : document.body.style.pointerEvents = "auto" }

    return (

      <div>

        {loadState.load === true ?
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
            <MoonLoader size={300} color="#161459" />
          </div> : <div />}

        <Popover className="relative">
          {({ open }) => (
            <>
              <Popover.Button
                className={`
           ${open ? "" : ""}
            px-4 py-1.5 rounded-md inline-flex items-center font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 text-xs`}
              >
                <span style={{ marginRight: '5px' }}>{adults > 1 ? `${adults} Adults,` : `${adults} Adult,`}</span>
                <span style={{ marginRight: '5px' }}>{children == 1 ? `${children} Child,` : `${children} Children,`}</span>
                <span style={{ marginRight: '5px' }}>{infants == 1 ? `${infants} Infant` : `${infants} Infants`}</span>
                <ChevronDownIcon
                  className={`${open ? "" : "text-opacity-70"
                    } ml-2 h-4 w-4 group-hover:text-opacity-80 transition ease-in-out duration-150`}
                  aria-hidden="true"
                />
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute z-10 px-4 mt-3 transform -translate-x-1/2 left-1/2 sm:px-0 " style={{ paddingRight: '55%', minWidth: '450px' }}>
                  <div className="overflow-hidden rounded-2xl shadow-lg ring-1 ring-black/5 dark:ring-white/10">
                    <div className="relative bg-white dark:bg-neutral-800 p-4">
                      <div style={{ display: 'flex', padding: '3px' }}>
                        <div style={{ flex: '1' }}>Adults</div>
                        <div style={{ flex: '1', fontSize: '12px', opacity: '0.4' }}>16+ years</div>
                      </div>
                      <NcInputNumber
                        onChange={(e) => { setAdults(e); setAdultArray([e]) }}
                        min={1}
                        defaultValue={adults}
                        max={20}
                      />
                      <div style={{ display: 'flex', padding: '3px' }}>
                        <div style={{ flex: '1' }}>Children</div>
                        <div style={{ flex: '1', fontSize: '12px', opacity: '0.4' }}>2-15 years</div>
                      </div>
                      <NcInputNumber
                        onChange={(e) => { setChildren(e); setChildArray([e]) }}
                        min={0}
                        defaultValue={children}
                        max={20}
                      />
                      <div style={{ display: 'flex', padding: '3px' }}>
                        <div style={{ flex: '1' }}>Infants</div>
                        <div style={{ flex: '1', fontSize: '12px', opacity: '0.4' }}>under 2 years</div>
                      </div>
                      <NcInputNumber
                        onChange={(e) => { setInfants(e);; setInfantArray([e]) }}
                        min={0}
                        defaultValue={infants}
                        max={20}
                      />
                    </div>
                  </div>
                </Popover.Panel>

              </Transition>
            </>
          )}
        </Popover>
      </div>
    );
  };

  const renderSelectClass = () => {
    return (
      <div className="">
        <Popover className="relative">
          {({ open, close }) => (
            <>
              <Popover.Button
                className={`
           ${open ? "" : ""}
            px-4 py-1.5 rounded-md inline-flex items-center font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 text-xs`}
              >
                <span>{`${flightClassState}`}</span>
                <ChevronDownIcon
                  className={`${open ? "" : "text-opacity-70"
                    } ml-2 h-4 w-4 group-hover:text-opacity-80 transition ease-in-out duration-150`}
                  aria-hidden="true"
                />
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute z-10 w-screen max-w-[200px] sm:max-w-[220px] px-4 mt-3 transform -translate-x-1/2 left-1/2 sm:px-0 ">
                  <div className="overflow-hidden rounded-2xl shadow-lg ring-1 ring-black/5 dark:ring-white/10 ">
                    <div className="relative grid gap-8 bg-white dark:bg-neutral-800 p-7 ">
                      {flightClass.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          onClick={(e) => {
                            e.preventDefault();
                            setFlightClassState(item.name);
                            setCabinClass(item.cabin_class)
                            close();

                          }}
                          className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                        >
                          <p className="text-sm font-medium ">{item.name}</p>
                        </a>
                      ))}
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      </div>
    );
  };

  const renderRadioBtn = () => {
    return (
      <div className=" py-5 [ nc-hero-field-padding ] flex flex-row flex-wrap border-b border-neutral-100 dark:border-neutral-700">
        <div
          className={`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer mr-2 my-1 sm:mr-4 ${dropOffLocationType === "roundTrip"
            ? "bg-black shadow-black/10 shadow-lg text-white"
            : "border border-neutral-300 dark:border-neutral-700"
            }`}
          onClick={(e) => setDropOffLocationType("roundTrip")}
        >
          Round-trip
        </div>
        <div
          className={`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer mr-2 my-1 sm:mr-4 ${dropOffLocationType === "oneWay"
            ? "bg-black text-white shadow-black/10 shadow-lg"
            : "border border-neutral-300 dark:border-neutral-700"
            }`}
          onClick={(e) => setDropOffLocationType("oneWay")}
        >
          One-way
        </div>
        <div className=" mr-2 my-1 sm:mr-4 border border-neutral-300 dark:border-neutral-700 rounded-full">
          {renderSelectClass()}
        </div>
        <div className="my-1 border border-neutral-300 dark:border-neutral-700 rounded-full">
          {renderGuest()}
        </div>
      </div>
    );
  };

  const renderForm = () => {
    return (
      <div className="w-full">
        <form onSubmit={handleSubmit} className="w-full relative mt-8 rounded-3xl shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800">
          {renderRadioBtn()}
          <div className=" flex flex-col md:flex-row w-full rounded-full [ nc-divide-field ] ">
            <div className="relative flex flex-col md:flex-row flex-grow [ nc-divide-field ] "
            // style={ validDestination == false ? {color: 'red'} : {color: ""} }
            >
              <LocationInput
                defaultValue={pickUpInputValue}
                onChange={(e) => setPickUpInputValue(e)}
                onInputDone={() => setFieldFocused("dropOffInput")}
                placeHolder="Flying from"
                desc="Where do you want to fly from?"
              />
              <LocationInput
                defaultValue={dropOffInputValue}
                onChange={(e) => setDropOffInputValue(e)}
                onInputDone={() => setFieldFocused("startDate")}
                placeHolder="Flying to"
                desc="Where you want to fly to?"
                autoFocus={fieldFocused === "dropOffInput"}
              />
            </div>
            <RentalCarDatesRangeInput
              defaultDateValue={dateRangeValue}
              defaultTimeValue={timeRangeValue}
              defaultFocus={
                fieldFocused === "dropOffInput" ? null : fieldFocused
              }
              onFocusChange={(focus) => setFieldFocused(focus)}
              onChange={(data) => {
                setDateRangeValue(data.stateDate);
                setTimeRangeValue(data.stateTimeRage);
              }}
            />
            {/* BUTTON SUBMIT OF FORM */}
            <div className="px-4 py-3 flex items-center justify-center">
              <div onClick={handleSubmit}>
                <ButtonSubmit />
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  };

  return renderForm();
};



const connector = connect(null, null);
// type SearchFormProps = ConnectedProps<typeof connector>;

export default connector(FlightSearchForm);
