import React, { useEffect, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import CommonLayout from "./CommonLayout";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { actionCreators, State } from "state";

const AccountBilling = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const { cancelFlight } = bindActionCreators(actionCreators, dispatch);
  const state = useSelector((state: State) => state.storeCustomerDataReducer)
  const secondState = useSelector((state: State) => state.storeLoginReducer)

  const [clicked, setClicked] = useState(false)

  const handleCancelFlight = (index: any) => {
    setClicked(true)
    setTimeout(function () {
      state.customerData.filter(Boolean);
      cancelFlight(index)
    }, 5000);
  }

  useEffect(() => {
    if (secondState.loginData?.loggedIn === false) {
      history.push('/')
    }
  }, [secondState.loginData])


  return (
    <div>
      {state.customerData !== null ? <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">Flights</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          {state.customerData.map((card, index) => {
            return (
              card !== null ? <div className="max-w-2xl" key={index}>
                <div className="shadow overflow-hidden sm:rounded-lg">
                  <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium">Flight Details</h3>
                    <p className="mt-1 max-w-2xl text-sm">Your flight from {card?.from} to {card?.to}</p>
                    <div className="flex-shrink-0 w-full sm:w-40"><div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
                      <img src={card?.airlineLogo} />
                    </div></div>
                  </div>
                  <div className="border-t border-gray-200">
                    <dl>
                      <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium">Full Name</dt>
                        <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">{card?.firstName[0].name_0} {card?.lastName[0].name_0}</dd>
                      </div>
                      <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium">Flight Overview</dt>
                        <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">{card?.from} to {card?.to}</dd>
                      </div>
                      <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium">Confirmation Number</dt>
                        <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">{card?.confirmationNumber}</dd>
                      </div>
                      <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium">Email address</dt>
                        <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">{card?.email}</dd>
                      </div>
                    </dl>
                  </div>
                </div>
                <div className="pt-10">
                  <ButtonPrimary key={index} loading={clicked ? true : false} onClick={() => handleCancelFlight(index)}>Cancel Flight</ButtonPrimary>
                </div>
              </div> : <div />

            )
          })}
        </div>
      </CommonLayout> : <CommonLayout></CommonLayout>
      }

    </div >
  );
};

export default AccountBilling;
