import { DEMO_POSTS } from "data/posts";
import { PostDataType } from "data/types";
import React from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Comment from "shared/Comment/Comment";
import NcImage from "shared/NcImage/NcImage";
import SocialsList from "shared/SocialsList/SocialsList";
import Textarea from "shared/Textarea/Textarea";
import { Helmet } from "react-helmet";

const BlogSingleSingapore = () => {
  const renderHeader = () => {
    return (
      <header className="container rounded-xl">
        <div className="max-w-screen-md mx-auto space-y-5">
          {/* <Badge href="##" color="purple" name="Traveler" /> */}
          <h1
            className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
            title="Quiet ingenuity: 120,000 lunches and counting"
          >
            Explore Singapore
          </h1>
          <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
            With its century-old temples, bustling hawker centres and lush green spaces, Singapore’s varied charms are bound to enchant visitors to our island. Our city’s calendar of events is equally diverse, and present travellers with ample opportunities to explore, indulge and express their passions.
          </span>

          <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
          <div className="flex flex-col items-baseline sm:flex-row sm:justify-between">
            <div className="nc-PostMeta2 flex items-center flex-wrap text-neutral-700 text-left dark:text-neutral-200 text-sm leading-none flex-shrink-0">
              {/* <Avatar
                containerClassName="flex-shrink-0"
                sizeClass="w-8 h-8 sm:h-11 sm:w-11 "
              /> */}
              {/* <div className="ml-3">
                <div className="flex items-center">
                  <a
                    className="block font-semibold"
                    href="/ncmaz/author/the-demo-author-slug"
                  >
                    Fones Mimi
                  </a>
                </div>
                <div className="text-xs mt-[6px]">
                  <span className="text-neutral-700 dark:text-neutral-300">
                    May 20, 2021
                  </span>
                  <span className="mx-2 font-semibold">·</span>
                  <span className="text-neutral-700 dark:text-neutral-300">
                    6 min read
                  </span>
                </div>
              </div> */}
            </div>
            {/* <div className="mt-3 sm:mt-0 sm:ml-3"> */}
            {/* <SocialsList /> */}
            {/* </div> */}
          </div>
        </div>
      </header>
    );
  };

  const renderContent = () => {
    return (
      <div
        id="single-entry-content"
        className="prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
      >
        <p>
          Singapore is a heady collection of cultures. You only need look at its vibrant festival calendar to feel it – visit in May for Hari Raya Puasa, August for Singapore National Day and October/November for Diwali. And that barely scratches the surface. As well as the four ethnic precincts showcasing each of the diverse cultures of Singapore, there’s also a plethora of stunning religious buildings. No visit here is complete without seeing the towering Sri Mariamman Temple, stepping inside the bright red stupa of the Buddha Tooth Relic Temple, worshipping Chinese sea goddess Mazu at Thian Hock Keng, and venturing through the gates of Little India’s Abdul Gafoor Mosque.
        </p>
        <p>
          Like us, you may be looking to improve the environmental footprint of the way you travel in light of the pandemic. Well, you’re in luck, because Singapore is BIG on sustainability. According to The Environmental Performance Index, it ranked 14th globally and first in Asia for its environmentally-friendly practices. Indeed, the city-state has its own Green Mark scheme to encourage greener buildings, offers a range of planet-conscious food options, and is planning several reliable and green energy solutions, including the world’s largest floating solar panel bed, located to the north of the island.
        </p>

        <p>
          Blissful humidity, sun and plenty of pools to cool off in (Marina Bay Sands we’re looking at you). December to June is the best time to visit, with February-April being the city-state’s main dry season but, honestly, we’d be happy with the weather here anytime.
        </p>
        <p>
          A big part of Singapore’s successful approach to coronavirus has been its SG Clean certification programme. This initiative is all about encouraging a clean culture – from regular hand washing to a slew of other important hygiene habits, not to mention protocols for public places, such as hotels, restaurants, bars and shops. As of 28 June 2021, more than 31,000 individual premises have certified SG Clean across Singapore. So, whether you’re exploring Singapore Zoo or venturing through Gardens by the Bay, you can rest assured that all the high-touch areas are good to go.
        </p>
        <figure>
          {/* <img
            src="https://images.unsplash.com/photo-1501493870936-9c2e41625521?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=1045&amp;q=80"
            alt="nc blog"
            className="rounded-2xl"
          /> */}
          {/* <figcaption>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Iure vel
            officiis ipsum placeat itaque neque dolorem modi perspiciatis dolor
            distinctio veritatis sapiente
          </figcaption> */}
        </figure>

      </div>
    );
  };

  const renderTags = () => {
    return (
      <div className="max-w-screen-md mx-auto flex flex-wrap">
        <a
          className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
          href="##"
        >
          Garden
        </a>
        <a
          className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
          href="##"
        >
          Jewelry
        </a>
        <a
          className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
          href="##"
        >
          Tools
        </a>
      </div>
    );
  };

  const renderAuthor = () => {
    return (
      <div className="max-w-screen-md mx-auto ">
        <div className="nc-SingleAuthor flex">
          <Avatar sizeClass="w-11 h-11 md:w-24 md:h-24" />
          <div className="flex flex-col ml-3 max-w-lg sm:ml-5 space-y-1">
            <span className="text-xs text-neutral-400 uppercase tracking-wider">
              WRITEN BY
            </span>
            <h2 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
              <a href="/ncmaz/author/the-demo-author-slug">Fones Mimi</a>
            </h2>
            <span className="text-sm text-neutral-500 sm:text-base dark:text-neutral-300">
              There’s no stopping the tech giant. Apple now opens its 100th
              store in China.There’s no stopping the tech giant.
              <a
                className="text-primary-6000 font-medium ml-1"
                href="/ncmaz/author/the-demo-author-slug"
              >
                Readmore
              </a>
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderCommentForm = () => {
    return (
      <div className="max-w-screen-md mx-auto pt-5">
        <h3 className="text-xl font-semibold text-neutral-800 dark:text-neutral-200">
          Responses (14)
        </h3>
        <form className="nc-SingleCommentForm mt-5">
          <Textarea />
          <div className="mt-2 space-x-3">
            <ButtonPrimary>Submit</ButtonPrimary>
            <ButtonSecondary>Cancel</ButtonSecondary>
          </div>
        </form>
      </div>
    );
  };

  const renderCommentLists = () => {
    return (
      <div className="max-w-screen-md mx-auto">
        <ul className="nc-SingleCommentLists space-y-5">
          <li>
            <Comment />
            <ul className="pl-4 mt-5 space-y-5 md:pl-11">
              <li>
                <Comment isSmall />
              </li>
            </ul>
          </li>
          <li>
            <Comment />
            <ul className="pl-4 mt-5 space-y-5 md:pl-11">
              <li>
                <Comment isSmall />
              </li>
            </ul>
          </li>
        </ul>
      </div>
    );
  };

  const renderPostRelated = (post: PostDataType) => {
    return (
      <div
        key={post.id}
        className="relative aspect-w-3 aspect-h-4 rounded-3xl overflow-hidden group"
      >
        <Link to={post.href} />
        <NcImage
          className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-300"
          src={post.featuredImage}
        />
        <div>
          <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
        </div>
        <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-2.5 p-4">
          <Badge name="Categories" />
          <h2 className="block text-lg font-semibold text-white ">
            <span className="line-clamp-2">{post.title}</span>
          </h2>

          <div className="flex">
            <span className="block text-neutral-200 hover:text-white font-medium truncate">
              {post.author.displayName}
            </span>
            <span className="mx-1.5 font-medium">·</span>
            <span className="font-normal truncate">{post.date}</span>
          </div>
        </div>
        <Link to={post.href} />
      </div>
    );
  };

  return (
    <div className="nc-PageSingle pt-8 lg:pt-16 ">
      <Helmet>
        <title>Explore Singapore || Travel 7 World</title>
      </Helmet>
      {renderHeader()}
      <NcImage
        className="w-full rounded-xl"
        containerClassName="container my-10 sm:my-12 "
        src="https://images.pexels.com/photos/7740160/pexels-photo-7740160.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
      />

      <div className="nc-SingleContent container space-y-10">
        {renderContent()}
        {/* {renderTags()} */}
        <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
        {/* {renderAuthor()} */}
        {/* {renderCommentForm()} */}
        {/* {renderCommentLists()} */}
      </div>
      {/* <div className="relative bg-neutral-100 dark:bg-neutral-800 py-16 lg:py-28 mt-16 lg:mt-24"> */}
      {/* <div className="container "> */}
      {/* <h2 className="text-3xl font-semibold">Related posts</h2> */}
      {/* <div className="mt-10 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8"> */}
      {/*  */}
      {/* {DEMO_POSTS.filter((_, i) => i < 4).map(renderPostRelated)} */}
      {/*  */}
      {/* </div> */}
      {/* </div> */}
      {/* </div> */}
    </div>
  );
};

export default BlogSingleSingapore;
