import React, { FC, useState } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link, useHistory } from "react-router-dom";

export interface PageSignUpProps {
  className?: string;
}

const loginSocials = [
  {
    name: "Continue with Facebook",
    href: "#",
    icon: facebookSvg,
  },
  {
    name: "Continue with Twitter",
    href: "#",
    icon: twitterSvg,
  },
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg,
  },
];

const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {

  const history = useHistory();

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  var emailRegex = "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$"

  const [validFirstName, setValidFirstName] = useState<boolean>()
  const [validLastName, setValidLastName] = useState<boolean>()
  const [validEmail, setValidEmail] = useState<boolean>()
  const [validPassword, setValidPassword] = useState<boolean>()


  const registerUser = async (e: { preventDefault: () => void; }) => {

    e.preventDefault()

    if (firstName === '') {
      setValidFirstName(false)
    } else {
      setValidFirstName(true)
    }

    if (lastName === '') {
      setValidLastName(false)
    } else {
      setValidLastName(true)
    }

    if (email.match(emailRegex)) {
      setValidEmail(true)
    } else {
      setValidEmail(false)
    }

    if (password.length < 6) {
      setValidPassword(false)
    } else {
      setValidPassword(true)
    }

    const res = await fetch('https://travel7world.com/api/register', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        firstName,
        lastName,
        email,
        password
      })
    })

    const data = await res.json()

    if (res.status === 200
      && validFirstName === true
      && validLastName === true
      && validEmail === true
      && validPassword === true) {
      history.push('/login')
    }
  }

  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>Register || Travel 7 World</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Signup
        </h2>
        <div className="max-w-md mx-auto space-y-6 ">
          <div className="grid gap-3">
            {/* {loginSocials.map((item, index) => (
              <a
                key={index}
                href={item.href}
                className="nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <img
                  className="flex-shrink-0"
                  src={item.icon}
                  alt={item.name}
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {item.name}
                </h3>
              </a>
            ))} */}
          </div>
          {/* OR */}
          {/* <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div> */}
          {/* FORM */}
          {/* <form className="grid grid-cols-1 gap-6" action="#" method="post"> */}
          <form className="grid grid-cols-1 gap-6" onSubmit={registerUser}>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                First Name
              </span>
              <Input
                type="text"
                placeholder="Alex"
                className="mt-1"
                value={firstName}
                onChange={(e) => { setFirstName(e.target.value); setValidFirstName(!false) }}
              />
              {validFirstName === false ? <div className="text-red-600">This field cannot be empty</div> : <div />}

            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Last Name
              </span>
              <Input
                type="text"
                placeholder="Smith"
                className="mt-1"
                value={lastName}
                onChange={(e) => { setLastName(e.target.value); setValidLastName(!false) }}
              />
              {validLastName === false ? <div className="text-red-600">This field cannot be empty</div> : <div />}
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email address
              </span>
              <Input
                type="email"
                placeholder="alexsmith@example.com"
                className="mt-1"
                value={email}
                onChange={(e) => { setEmail(e.target.value); setValidEmail(!false) }}
              />
              {validEmail === false ? <div className="text-red-600">Please enter a valid email address</div> : <div />}
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Password
              </span>
              <Input type="password"
                className="mt-1"
                value={password}
                onChange={(e) => { setPassword(e.target.value); setValidPassword(!false) }} />
              {validPassword === false ? <div className="text-red-600">Password must be at least 6 characters</div> : <div />}

            </label>
            <ButtonPrimary type="submit">Register</ButtonPrimary>
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Already have an account? {` `}
            <Link to="/login">Sign in</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageSignUp;
